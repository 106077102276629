.infoBlock{
    display: flex;
    flex-direction: column;
    row-gap: 3px; // Space between Title and Content components
}

.title {
    color: var(--grey-500);
    padding: 0 !important;
    height: auto;
    max-height: 12px;
    font-weight: 500;
    font-size: 0.6em;
}

.content {
    color: var(--grey-800);
    height: auto;
    font-weight: 400;
    font-size: 0.9em;
    text-overflow: ellipsis;
}

.noDataAvailable {
    @extend .content;
    color: var(--grey-300);
}