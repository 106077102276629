.account-property {
    height: 40px;
    width: 200px;
}

.note-icon {
    cursor: pointer;
    font-size: 20px;
    padding: 3px;
    border-radius: 2px;
}

.manage-assumptions-percentage-field {
    border: 1px solid var(--litesilver);
    border-radius: 6px;
    width: 250px;
    height: 45px;
    margin-left: 20%;
}

.manange-assumptions-input-field {
    width: 50px;
}

.manage-assumptions-summary {
    border: 1px solid var(--litesilver);
    border-radius: 6px;
    width: 300px;
    // height: fit-content;
}

.display-modal-icon-container {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    align-items: center;
}

.display-modal-icon {
    cursor: pointer;
}

.account-header-container {
    align-items: flex-end;
    // padding-left: 40px;
    padding-right: 85px;
}

.account-title {
    color: black;
    font-weight: bold;
    font-size: 20px;
}

.account-collapse-button {
    height: 35px;
    width: 130px;
    border-radius: 30px;
}

.account-ss-container {
    // padding-left: 40px;
    padding-right: 20px;
    position: relative;
}

.financial-metrics-container {
    width: 1327px;
    margin: auto;
}

.summary-toggle-container {
    padding-right: 40px;
}

.account-view {
    background-color: var(--background-gray);

    overflow-y: scroll;
    overflow-x: hidden;

    height: calc(100% - 90px);
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.account-view::-webkit-scrollbar {
    display: none;
}

@media(max-width: 1520px) {
    .account-view.main-container {
        padding-left: 0;
    }
}