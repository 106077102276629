@import "../../../../../../../../fonts.scss";

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 12px;
    border-bottom: 1px solid var(--grey-200);
    height: 44px;
    min-height: 44px;
    align-items: center;

    &Caption {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.00042765619582496583px;
        text-align: left;
    }

    &ModelingMethod {
        margin-left: auto;
    }
}

.content {
    height: calc(100% - 44px - 40px); // 44px for header and 40px for total
}

.contentWithOverride {
    height: calc(100% - 44px - 80px); // 44px or header and 80px for total with override
}


.totalBase {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
}

.total {
    @extend .totalBase;
    border-top: 2px solid var(--grey-200);
}

.totalWithOverride {
    @extend .totalBase;
    border-top: 2px solid var(--grey-200);
    background-color: var(--grey-50);

    .label {
        @extend .text-sm-default-medium;
        color: var(--grey-500)
    }

    .value {
        @extend .text-sm-default-medium;
        text-decoration: line-through;
        color: var(--grey-500)
    }
}

.override {
    @extend .totalBase;
}


.label {
    @extend .text-sm-default-semo-bold;
    color: var(--grey-800);
}

.value {
    @extend .text-sm-default-semo-bold;
    color: var(--grey-800);
    margin-left: auto;
}