.overviewCard{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    border-radius: 6px;
    padding: 20px;

    & .label{
        color: var(--grey-500);
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 500;
    }

    & .value{
        font-size: 1.5rem;
        font-weight: 500;
    }

    & .compareLine{
        color: var(--grey-500);
        font-size: 0.7rem;
        font-weight: 500;
    }
}

.positiveChange {
    background-color: var(--primary-30);

    & .value{
        color: var(--positive-color);
    }

    & .compareLine .percentChange{
        color: var(--positive-color);
    }
}

.negativeChange {
    background-color: var(--red-100);

    & .value{
        color: var(--negative-color);
    }

    & .compareLine .percentChange{
        color: var(--negative-color);
    }
}
