.modalBody {
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;

    .description {
        color: var(--grey-600);
    }

    span {
        color: var(--grey-400);
        font-weight: 500;
    }

    .stagedUsersContainer {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        max-height: 300px;
        gap: 1rem;

        .stagedUser {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &Header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                button {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
            .nameInputs {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                div {
                    width: calc(50% - 0.5rem);
                }
            }
        }
    }

    .addGuestButton {
        padding-left: 0;
        padding-right: 0;
        width: fit-content;
    }

    .inviteMailListContainer {
        display: flex;
        flex-direction: row;
        background-color: var(--grey-50);
        padding: 0.4rem 0.75rem;
        border-radius: 4px;
        border: 1px solid var(--grey-300);
        flex-wrap: wrap;
        row-gap: 0.3rem;
        column-gap: 0.7rem;

        .email {
            border-radius: 4px;
            padding: 0.2rem 0.7rem;
            background-color: var(--grey-150);
        }
    }

    textarea {
        height: 10rem;
    }

    .sharedLinksContainer {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        max-height: 300px;
        gap: 1.2rem;

        .sharedLink {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            &MenuButton {
                border-radius: 100% !important;
            }

            .person {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;
                left: auto;
                right: auto;

                &Name {
                    color: var(--grey-800);
                    font-weight: 600;
                }

                &Email {
                    color: var(--grey-500);
                }
            }

            .menuButton {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}

.modalFooter {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;

    &RightButtonContainer {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }
}