.configureMmModal {
    // The HoT cells have fixed widths so we want a stopping point
    max-width: 1423px !important;
    width: 90% !important;
}

.modalBody {
    // Overriding ZDG styles
    height: 75vh !important;
    min-height: 818px !important;
    overflow: hidden !important;
}

.sectionWrapper {
    max-width: 1366px;
}

.headerActionsWrapper {
    text-align: right;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
}

.copyJobStatusText {
    color: var(--grey-400);
    font-size: .75rem;
    position: relative;
    top: -2px;
}

.finalizeToggle {
    display: inline-block;
}

.pageHeader {
    padding: 100px 0 24px;
}

.pageTitle {
    margin-bottom: 1.5rem;
    color: var(--grey-800);

    .pageTitleLockIcon {
        margin-left: .65rem;
        margin-top: -.35rem;
        color: var(--grey-400);
    }
}

// Footer
.footerWrapper {
    align-items: center;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 30px rgba(135, 146, 157, 0.25);
    display: flex;
    gap: 20px;
    margin: 12px 0 0 !important;
    width: 100%;
}

.footer {
    align-items: center;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 30px rgba(135, 146, 157, 0.25);
    display: flex;
    flex-direction: row;
    margin: 12px 0 0;
    width: 100%;
}

.counterWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 0 !important;
    padding: 0 !important;
    min-width: 320px;
}

.copyDriversBtnCol {
    display: flex;
    flex-direction: column;
    flex-grow: 0 !important;
    padding: 0 !important;
}

.dummyFlexWrapper {
    display: flex;
}

.counter {
    background-color: var(--primary-200);
    border-radius: 4px 0 0 4px;
    display: flex;
    font-size: 2rem;
    font-weight: 600;
    padding: 11px 30px;
}

.counterLabel {
    align-items: center;
    color: var(--grey-600);
    display: flex;
    font-size: 1.1rem;
    font-weight: 400;
    margin-left: 9px;
}

.savePdToast {
    right: 59px;
    width: 370px;
}

.jobLogModal {
    width: 90vw !important;

    .jobLogModalBody {
        padding: 0;
        padding-bottom: 1.5rem;
    }
}
