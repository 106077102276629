.otpWrapper {
    padding-top: 17vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .welcome {
        font-size: 36px;
        line-height: 44px;
        color: var(--grey-800);
    }

    .logo {
        height: 64px;
        width: 200px;
    }

    .prompt {
        font-size: 22px;
        line-height: 28px;
        color: var(--grey-800);
    }

    .sendButton {
        margin-right: 0;
        margin-left: auto;
        width: fit-content;
    }
}

.otpRight {
    background-color: var(--primary-500);
    height: 100vh;
    padding-top: 10vh;
}