.as-accounts {
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 180px;
}

.category-header {
    align-items: center;
    padding-left: .75rem;
    border: 1px solid var(--bg-grey);
}
