.btnAddStaff{
    float: right;
    margin-top: 26px;
    //margin-right: 46px;
    max-width: 111px;
    position: relative;
    background-color: #0A8585;
    &:hover{
        background-color: #0A8585;
    }
}
