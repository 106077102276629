.modal {
    width: 80rem !important;
}

.inputBox {
    width: 50rem;
    float: left;
    margin-left: 1rem !important;
}

.checkbox {
    top: -1rem;

    &:before,
    > svg {
        width: 32px !important;
        height: 32px !important;
    }
}

.listHeader {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid var(--grey-100);
    display: flex;
    flex-direction: row;

    .tag {
        width: 10rem;
    }

    .checkbox {
        top: unset;
    }
}

.headerButton {
    svg {
        min-width: 1rem;
        min-height: 1rem;
    }
}


.lookbackContainer {
    width: 30rem;
    margin-left: 0;
    margin-right: auto;
    height: 50px;
    display: flex;
    align-items: center;
    transition: background-color 0.5s;
    padding-left: 0.5rem;
    &:hover{
        background-color: var(--primary-100);
        border-radius: 4px;
    }

    span {
        max-width: 100%;
        max-height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.item {
    box-sizing: border-box;
    user-select: none;

    /* center align text */
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;

    .tag {
        width: 10rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .content {
        font-weight: 500;
        font-size: 12px;
        font-family: Inter;
        color: black;
        user-select: text;

        display: flex;
        justify-content: left;
        align-items: center;
        width: 50rem;

        .accountName {
            width: calc(100% - 96px);
        }
    
        &.plain {
            font-weight: 300;
        }

        .chevron {
            width: 48px;
        }
    }
}

.wrapper {
    padding: .5rem;
    padding-top: 100px;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 0 auto;

    .tableContent {
        padding: 1rem;
    }
}

.emptyCheckmarkSpacer {
    width: 16px;
}

.valContainerIcon {
    display: flex;
    flex-direction: column;
}

.valContainerInputWrapper {
    cursor: text;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.dummyFlexWrapper {
    display: flex;
}

.footerWrapper {
    display: flex;
    flex-direction: raw;
    .counterWrapper {
        display: flex;
        flex-direction: row;
        flex-grow: 0 !important;
        padding: 0 !important;
        margin-left: 0;
        margin-right: auto;
        min-width: 320px;
    
        .counter {
            background-color: var(--primary-200);
            display: flex;
            font-size: 2rem;
            font-weight: 600;
            padding: 11px 30px;
        }
        
        .counterLabel {
            align-items: center;
            color: var(--grey-600);
            display: flex;
            font-size: 1.1rem;
            font-weight: 400;
            margin-left: 9px;
        }
    }
    
    .bottomControlsWrapper {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: 1rem;
        align-items: center;

        button:not(:first-child) {
            margin-left: 0.5rem;
        }
    }
}
