@import "../../colors";

$account-instruction-drawer-padding: 1rem;

/** Slide-in Animation Code. This could be reusable*/
@mixin slide-in-right-start-state {
    transform: translateX(100%);
}

.slide-in-right {
    animation: slide-in 0.5s forwards;
}

.slide-out-right {
    animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

/* End animation code */

.viziblyAccountInstructionButton {
    color: #5D6CB1 !important;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
}

.viziblyAccountInstructionsDrawerBackdrop {
    background-color: transparent;
}

.viziblyAccountInstructionsDrawerDialog {
    @include slide-in-right-start-state;
    margin: 0;
    padding: 75px 0 0 0;
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100vh - 70px);
    width: 25%;
    min-width: 225px;
    max-width: 325px;
    overflow: hidden;

    .viziblyAccountInstructionsDrawerContent {
        height: 100%;
        width: 100%;

        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: -1.5rem;
            height: 100%;
            width: 1.5rem;
            background: linear-gradient(90deg, transparent 0%, rgba(map-get($theme-colors, "primary"), 0.1) 85%, rgba(map-get($theme-colors, "primary"), 0.2) 100%);
        }
    }
}

.contentBody {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 40px;
        margin: 15px 0;
        color: #333842;
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid var(--grey-200);

        .headerTextContainer {
            display: flex;
            flex-direction: row;

            .headerTextIcon {
                width: 24px;
                height: 24px;
                margin-right: 7px;
            }

            .headerText {
                margin-top: -1px;
            }
        }

        .closeButton {
            min-width: unset;
            width: 1rem;
            height: 1rem;
        }
    }

    .instructionText {
        color: #667085;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
    }
}