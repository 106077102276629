.modal {
    width: 80rem !important;
}

.inputBox {
    min-width: 100px;
    flex-grow: 1;
    margin-left: 1rem !important;
}

.listHeader {
    width: 100%;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--grey-100);

    .tag {
        width: 10rem;
    }

    .checkbox {
        top: unset;
    }
}

.listHeaderContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.listHeaderInputBoxContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
}

.listHeaderButtonContainer {
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: space-around;
    align-items: center;
}

.checkbox {
    top: -1rem;

    &:before,
    > svg {
        width: 32px !important;
        height: 32px !important;
    }
}

.headerButton {
    svg {
        min-width: 1rem;
        min-height: 1rem;
    }
}

.lookbackContainer {
    width: 12rem;
    margin-left: auto;
    margin-right: 0;
}

.item {
    box-sizing: border-box;
    user-select: none;

    /* center align text */
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    .tag {
        width: 10rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &.hasCycle {
        background-color: rgba(255, 0, 0, 40%);
    }

    &.childrenHaveCycle {
        background-color: rgba(255, 0, 0, 10%);
    }

    .content {
        font-weight: 500;
        font-size: 12px;
        font-family: Inter;
        color: black;
        user-select: text;

        display: flex;
        justify-content: left;
        align-items: center;
        width: 50rem;

        .accountName {
            width: calc(100% - 96px);
        }

        .cycleIcon {
            width: 48px;
            color: red;
        }

        &.plain {
            font-weight: 300;
        }

        .chevron {
            width: 48px;
        }
    }
}