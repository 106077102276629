.fxNode {
    display: flex;
    column-gap: 2px;
    flex-wrap: wrap;
    align-items: center;

    font-family: inherit;

    cursor: pointer;
    white-space: nowrap;

    & a:not([href]) {
        color: var(--primary-500);
        font-family: inherit;

        &:hover {
            color: var(--primary-500);
            background-color: var(--primary-100);
            font-family: inherit;
            cursor: pointer;
        }
    }

    & span {
        flex-wrap: nowrap;
        font-family: inherit;
    }
}

.fxNodeLocked{
    display: flex;
    column-gap: 2px;
    flex-wrap: wrap;
    align-items: center;

    font-family: inherit;
    color: var(--grey-500);

    pointer-events: none;
    white-space: nowrap;

    & span {
        flex-wrap: nowrap;
        font-family: inherit;
    }
}

.junctionNode {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    max-height: 14px;
    padding: 4px;
    color: var(--primary-500);
    border-radius: 3px;

    &Locked {
        color: var(--grey-500);
        margin-left: 5px;
        pointer-events: none;
    }

    &:hover {
        background-color: var(--primary-100);
        cursor: pointer;
    }
}

.clickableJunctionNode {
    // An exception for the '+' trigger node so the whole highlighted area is clickable
    margin: -4px;
    padding: 4px;
}

.deletableNode {
    align-items: center;
    border-radius: 3px;
    display: flex;
    font-family: inherit;
    height: fit-content;
    width: fit-content;
    &:hover {
        background-color: rgba(13, 102, 109, 0.1);
        cursor: pointer;
    }
}

.focusedNode {
    background-color: rgba(13, 102, 109, 0.2);
    cursor: pointer;
    // Override deletetableNode's hover styles
    &:hover {
        background-color: rgba(13, 102, 109, 0.2);
    }
}

.payrollMenuContainer {
    font-family: inherit;
}

.payrollWrapper {
    display: flex;
    white-space: pre;
    flex-direction: row;
    font-family: inherit;
}

.payrollCompItem {
    display: inline;
    font-family: inherit;
}

.disableHover {
    &:hover {
        background-color: initial;
    }
}

.inlineBlock {
    display: inline-block;
    font-family: inherit;
}
