.commentThreadDrawer {
    // @bowman - zendesk fights me on width and I dont want to global override the component
    width: 100vw !important;
    max-width: 440px;

    .header {
        .title {
            display: flex;
            flex-direction: column;

            h4, h5 {
                margin: 0;
                line-height: 1.5;
            }

            h5 {
                font-weight: 600;
                font-size: .75rem;
            }

            h4 {
                font-size: .875rem;
                color: var(--grey-600);
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        padding: 0;

        .noCommentsWrapper {
            opacity: 1;
            background-color: var(--grey-50);
            flex-grow: 1;
            text-align: center;
            align-content: center;
            color: var(--grey-300);

            .icon {
                font-size: 6rem;
                margin-bottom: 1rem;
            }

            h5 {
                font-size: 1rem;
            }
        }

        .dateSeparator {
            background-color: var(--grey-50);
            color: var(--grey-500);
            padding: .5rem 1rem;
            border-bottom: 1px solid var(--grey-100);
            font-size: .75rem;
            font-weight: 500;
            position: sticky;
            top: 0;
        }

        .comment {
            margin-top: 1.5rem;
            padding-bottom: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;

            &:not(:last-child) {
                border-bottom: 1px solid var(--grey-100);
            }

            .commentMeta {
                display: inline-block;
                font-size: 1rem;
                margin-bottom: 1rem;
                font-weight: 500;

                span {
                    margin-left: .5rem;
                    color: var(--grey-400);
                    font-size: .75rem;
                    font-weight: 300;
                }
            }

            .commentText {
                color: var(--grey-800);
                text-align: left;
                border-radius: 6px;
                border-bottom-left-radius: 0;
                margin: 0;
                font-size: .875rem;
                line-height: 1.5;
                font-weight: 300;
            }

            // styling for when the comment was left by the current user
            &.selfComment {
                text-align: right;
                align-self: flex-end;

                .commentMeta {
                    display: inline-block;
                    padding: .5rem;
                    font-size: .75rem;
                    margin-bottom: 0;
                }

                .commentText {
                    text-align: left;
                    background-color: #ffffff;
                    border-radius: 6px;
                    border-bottom-right-radius: 0;
                    padding: 1rem;
                }
            }

            &.denseSpacing {
                .commentText {
                    margin-top: -.75rem;
                }
            }
        }

        .endOfComments {
            margin-top: 1rem;
            text-align: center;
            color: var(--grey-400);

            .innerWrapper {
                height: 60px;
                position: relative;
                overflow: hidden;
                width: 100%;

                span {
                    position: absolute;
                    bottom: 4px;
                    right: 312px;
                }

                .icon {
                    color: var(--grey-300);
                    position: absolute;
                    font-size: 6rem;
                    bottom: -2.25rem;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }

    .footer {
        align-items: end;

        .inputFieldWrapper {
            flex-grow: 1;
            margin-top: -1px;

            .inputField {
                width: 100%;
            }
        }
    }
}