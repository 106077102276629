.dashHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.propertyRow {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding-bottom: 4px;
    color: var(--primary-500);
    font-size: 0.8rem;
    font-weight: 500;
    border-bottom: 1px solid var(--grey-100);
}

.timeframeRow {
    display: flex;
    padding: 12px 4px;
    column-gap: 12px;
    color: var(--grey-600);
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid var(--grey-100);
}
