.custom-zd-dropdown-autocomplete {
    // min-height: calc(3.5rem + 2px) !important;
    height: 40px;
    align-items: center !important;

    input::placeholder {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: #66708580;
    }

    > svg {
        width: 10px;
        height: 10px;
    }
}

.custom-zd-dropdown-field {
    svg {
        margin: unset !important;
    }

    // width: 241px;
    // height: 40px;
}
