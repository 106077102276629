@import "../../../../../fonts.scss";

.settingsLabel {
    @extend .text-md-default-medium;
}

.focusedButton {
    background-color: var(--primary-100) !important;
}

.navMenuWrapper {
    min-width: 280px;
}

.navIconButton {
    right: -8px;
}

.menuHeaderItem {
    color: var(--grey-300);
    font-weight: 500
}

.logoutButton {
    border: none !important;
    justify-content: left !important;
    font-weight: 600 !important;
    border-radius: 0 !important;
    // margin-bottom: .25rem !important;

    .logoutButtonIcon {
        margin-right: .5rem;
    }
}