.userSelectionBar {
    width: 95%;
    display: flex;
    align-items: center;
    transition: opacity .55s cubic-bezier(0.190, 1.000, 0.220, 1.000), visibility .55s cubic-bezier(0.190, 1.000, 0.220, 1.000), bottom .55s cubic-bezier(0.190, 1.000, 0.220, 1.000);
    position: fixed;
    bottom: -2rem;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 1330px;
    border-radius: 4px;
    background-color: white;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    box-shadow: 0 0 2rem rgba(135, 146, 157, 0.25);

    &.userSelectionBarVisible {
        bottom: 1rem;
        visibility: visible;
        pointer-events: all;
        opacity: 1;
    }

    .userSelectionBarCount {
        width: 120px;
        background-color: var(--primary-200);
        position: relative;
        border-radius: 4px 0 0 4px;
        padding: 2.25rem 1rem;
        margin-right: 1rem;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.25rem;
            font-weight: 600;
        }
    }

    // hard width is to prevent elements jumping when multi-selecting
    h4 {
        width: 150px;
        color: var(--grey-600);
        margin-right: 8rem;
        margin-bottom: 0;
        font-weight: 400;
    }

    // hard width is to prevent elements jumping when multi-selecting
    .userSelectionButton {
        width: 120px;
        margin-left: 2rem;

        // for now, this is more visually graceful since it prevents the delete button from
        // jumping over when multiple users are selected
        &.userSelectionButtonHidden {
            opacity: 0;
            pointer-events: none;
        }
    }
}