.line-component-cell {
    align-items: center;
    padding-left: .75rem;
}

.line-component {
    background-color: var(--whisper);
    font-size: 12px;
    font-weight: bold;
}

.component-header-title {
    padding-left: 24px;
    font-weight: bold;
}

.line-arrow-right-icon {
    font-size: 17px;
    color: var(--blue-ribbon);
    text-align: center;
    margin-bottom: 6px;
}

.totals {
    padding-right: 12px;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
