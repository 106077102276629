//styleName: Text-md/default-bold;
.text-md-default-bold {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.15399999916553497px;
    text-align: center;
}

//styleName: Text-md/default;
.text-md-default {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.15399999916553497px;
    text-align: center;
}

//styleName: Text-md/default-medium;
.text-md-default-medium {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.15399999916553497px;
    text-align: center;
}

//styleName: Text-xxl/default-semi-bold;
.text-xxl-default-semi-bold {
    font-family: Inter;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.35357466340065px;
    text-align: left;
}


//styleName: Text-md/default-semi-bold;
.text-md-default-semi-bold {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.15399999916553497px;
    text-align: left;
}

//styleName: Text-sm/default-medium;
.text-sm-default-medium {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
    text-align: left;
}

//styleName: Text-sm/default-semi-bold;
.text-sm-default-semo-bold {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
    text-align: left;
}