.sectionHeader {
    color: var(--grey-800);
    margin-left: -1em;
}

.compoundSectionHeader {
    display: flex;
    flex-direction: row;

    button {
        height: 23px;
        margin-left: 0.5em;
    }
}

.section {
    padding: 0 2em;
    margin-bottom: 2em;
}
