.spContainer {
    margin-top: 1rem;
    border-bottom: 1px solid var(--grey-100);
    padding-bottom: 1rem;

    // TODO remove this class once we kill the old StartPlanning page
    &.spContainerOldLandingPage {
        margin: 100px 75px 0 !important;
        width: unset !important;
    }

    .spTitle {
        font-size: 24px;
        font-weight: 500;
        color: #333842 !important;
    }

    .startPlanningCard {
        cursor: pointer;
        border-radius: 4px;
        padding-top: 15px;
        padding-bottom: 15px;

        img {
            width: 90px;
        }

        .spBtn {
            margin-top: 15px;
            padding: 10px 0;
            width: 200px;
            background-color: #0D666D;
            border-radius: 4px;
            border: 1px solid #0D666D;
            color: #FFFFFF;
            font-size: 14px;
        }
    }

    .startPlanningCard.revenue {
        background-color: #FFF8EF;
    }

    .startPlanningCard.payroll {
        background-color: #F9DFDC;
    }

    .startPlanningCard.accounts {
        background-color: #C7E4CE;
    }
}