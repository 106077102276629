.datePickerWrapper {
    text-align: right;
    border-bottom: 1px solid var(--grey-200);
    margin-bottom: 12px;
    padding: 3px 0 2px 0;
}

.datePicker {
    color: var(--grey-800);
    font-size: 10px;
    font-weight: 600;
}

.title {
    font-weight: 600;
    font-size: 10px;
    color: var(--grey-500);
    margin-bottom: 6px;
}

.value {
    font-weight: 600;
    font-size: 18px;
    color: var(--grey-800);
    margin-bottom: 16px;
}

.valueSmall {
    font-size: 14px;
}

.valueRed {
    color: red;
}

.valueGreen {
    color: green;
}

.chartWrapper {
    margin: 0 auto;
    width: 90%;
}

.overviewChartTooltip {
    display: flex;
    flex-direction: column;
}