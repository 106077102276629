* {
    font-family: 'Inter', sans-serif;
}

body {
    background-color: var(--white) !important;
    margin: 0;
    padding: 0;
}

html, body, #root {
    height: 100%;
}
