@import "../../colors";

/** Slide-in Animation Code. This could be reusable*/
@mixin slide-in-right-start-state {
    transform: translateX(100%);
}

.slide-in-right {
    animation: slide-in 0.5s forwards;
}

.slide-out-right {
    animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

/* End animation code */

.vizibly-analyst-drawer-backdrop.modal-backdrop {
    background-color: transparent;
}

.vizibly-analyst-drawer-dialog {
    @include slide-in-right-start-state;

    &.modal-dialog {
        margin: 0;
        padding: 75px 0 0 20px;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 60%;
        min-width: 960px;
        max-width: unset;
        overflow-y: hidden;
    }

    .vizibly-analyst-drawer-content {
        height: 100%;
        width: 100%;

        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: -1.5rem;
            height: 100%;
            width: 1.5rem;
            background: linear-gradient(90deg, transparent 0%, rgba(map-get($theme-colors, "primary"), 0.1) 85%, rgba(map-get($theme-colors, "primary"), 0.2) 100%);
        }
    }

    .modal-body {
        height: 100%;
    }
}

.vizibly-analyst-drawer-content .modal-body {
    padding: 0;
}

$analyst-drawer-padding: 1rem;

.analyst-drawer {
    max-height: calc(100vh - 75px);
}

.analyst-drawer-header {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    height: 145px;

    .analyst-drawer-header-text {
        padding: $analyst-drawer-padding $analyst-drawer-padding 0;
    }

    .analyst-drawer-close-button {
        position: absolute;
        top: $analyst-drawer-padding;
        right: $analyst-drawer-padding;
        min-width: unset;
        width: 1rem;
        height: 1rem;
    }
}

.analyst-drawer-tab-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid map-get($theme-colors, "lite-grey");

    .analyst-drawer-tab {
        position: relative;
        border: none;
        border-bottom: 3px solid transparent;
        background-color: transparent;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        font-weight: 500;
        color: #4b4b4b;

        &:hover,
        &.analyst-drawer-active-tab {
            border-bottom: 3px solid map-get($theme-colors, "primary-600");
            color: map-get($theme-colors, "primary-600");
        }

        &.dirty::after {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 0.5em;
            height: 0.5em;
            background-color: map-get($theme-colors, "secondary");
            border-radius: 50%;
        }

        &.analyst-drawer-active-tab.dirty::after {
            background-color: map-get($theme-colors, "primary");
        }
    }

    .op-driver-tab {
        width: 192px;
    }

    .worksheet-tab {
        width: 126px;
    }

    .acct-percent-tab {
        width: 135px;
    }

    .revenue-tab {
        width: 105px;
    }

    .payroll-tab {
        width: 93px;
    }
}

.analyst-drawer-body {
    height: calc(100vh - 220px);
    overflow-y: auto;
}

.analyst-drawer-alert-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;

    /* Red/Red-600 */

    color: #CC3340;
}

.analyst-drawer-actions-bar {
    background-color: #F8FBFC;
    padding: 0.625rem 1rem 0.625rem 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: end;

    .analyst-drawer-actions-button {
        background-color: transparent;
        border: 1px solid transparent;
        color: map-get($theme-colors, "primary");
        padding: 0.5rem 1rem;

        &:hover {
            border: 1px solid rgba(map-get($theme-colors, "primary"), 0.4);
            border-radius: 3px;
        }

        &:disabled {
            color: map-get($theme-colors, "grey");

            &:hover {
                border: 1px solid transparent;
            }
        }

        &.primary {
            background-color: map-get($theme-colors, "primary");
            color: white;
            border-radius: 4px;
        }
    }
}

.analyst-drawer-bulk-edit-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .analyst-drawer-bulk-edit-bar-back-button {
        color: black;
        padding: 0;
        margin: 0rem 1rem;
        align-items: center;

        &:hover {
            border: 1px solid transparent;
            font-weight: bold;
        }
    }

    .analyst-drawer-bulk-edit-bar-left {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-left: 1.5em;
    }

    .analyst-drawer-bulk-edit-bar-text {
        color: map-get($theme-colors, "grey-500");
        font-family: "Inter";
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
    }
}

.analyst-drawer-filters {
    padding: $analyst-drawer-padding;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .analyst-drawer-filters-right {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .filter-button-wrapper {
        margin-right: 0.25rem;

        &:last-child {
            margin-right: 0;
        }
    }
}