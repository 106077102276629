.headerRow{
    position: relative;
    display: flex;
    align-items: flex-start;
    min-height: 72px;
    padding: 24px 18px;

    justify-content: space-between;

    .leftCol{
        display: flex;
        column-gap: 5px;
        align-items: flex-start;
    }

    .rightCol{
        display: flex;
        column-gap: 16px;
        align-items: flex-start;
    }

    &NoBottomMargin{
        padding: 24px 18px 0 18px;
    }
}

.columnItemsContainer{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.rowItemsContainer{
    display: flex;
    column-gap: 8px;
    align-items: center;
}

.gridItemsContainer{
    display: flex;
    grid-gap: 8px;
}
