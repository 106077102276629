.label{
    font: 400 22px Inter;
    line-height: 28px;
    color: var(--grey-600);
    margin-right: 4px;
}

.subLabel{
    font: 400 22px Inter;
    line-height: 28px;
    color: var(--grey-600);
}
