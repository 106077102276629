@import "../../colors";

$analyst-drawer-padding: 1rem;

.analystPayrollModal {
    overflow: hidden;
}

.propertyRow {
    margin-top: 1em;

    &:first-child {
        margin-top: 0;
    }

    .propertyInputs {
        display: flex;
        flex-direction: row;
        gap: 1em;

        .propertyInputSelect {
            flex-grow: 1;
        }

        .propertyInputAllocation {
            flex-basis: 35%;
        }
    }

    .buttonRow {
        display: flex;
        flex-direction: row;
        justify-content: right;
    }
}

.analystPayrollDrawer {
    max-height: calc(100vh - 75px);
    overflow: auto;

    .tabPanels {
        height: calc(100vh - 75px - 75px - 60px - 48px - 30px); // topHeader, drawerHeader, tabsTitles, save_update_footer, scroll bars offsets
        overflow: auto;
    }

    .header {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: space-between;
        height: 75px;

        .headerText {
            padding: $analyst-drawer-padding $analyst-drawer-padding 0;
        }

        .closeButton {
            position: absolute;
            top: $analyst-drawer-padding;
            right: $analyst-drawer-padding;
            min-width: unset;
            width: 1rem;
            height: 1rem;
        }
    }

    .staffDetailsContainer {
        padding: 20px;
        border-right: 2px solid var(--grey-200);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .staffDetailsHeader {
            margin-bottom: 47px;
        }
    }

    .field {
        margin-bottom: 16px !important;

        .label {
            font-family: Inter;
            color: #2F3941;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 5px;
        }
    }

    .addButton {
        padding-left: 0
    }

    .positionDetailsContainer {
        display: flex;
        justify-content: center;

        .positionDetailsHeader {
            margin-bottom: 47px;
        }
    }

    .earningsContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .earningsSections {
            display: flex;
            padding-top: 20px;
            justify-content: center;
            border-bottom: 1px solid #667085;

            .earningsHeaders {
                margin-bottom: 16px;
            }

            .earningsSubHeaders {
                font-family: "Inter";
                font-weight: 600;
                font-size: 14px;
                color: #667085;
                margin-bottom: 8px;
            }
        }

        .earningsSections:last-child {
            border-bottom: none;
        }
    }

    .benefitsContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .benefitsSections {
            display: flex;
            padding-top: 20px;
            justify-content: center;
            border-bottom: 1px solid #667085;

            .benefitsHeaders {
                margin-bottom: 16px;
            }

            .benefitSubHeaders {
                font-family: "Inter";
                font-weight: 600;
                font-size: 14px;
                color: #667085;
                margin-bottom: 8px;
            }
        }

        .benefitsSections:last-child {
            border-bottom: none;
        }
    }

    .footerActionsBar {
        background-color: #F8FBFC;
        padding: 0.25rem 5rem 0.25rem 1rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: right;

        .footerActionsBarCancelButton {
            margin-right: 1rem;
        }

        .footerActionsBarSaveButton {
            background-color: #0a8585;
        }
    }


}