.sheetContainer{
    position: relative;
    z-index: 2;
    width: calc(100vw - 40px);
    min-width: 1472px;
    height: calc(100vh - 267px);
    margin: 0 auto;

    border: 1px solid var(--grey-200);

    &Hidden {
        display: none;
    }
}

.headerGroup{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D666D;
    height: 20px;
}

.entityColumn{
    color: #333842;
}

.dataColumn{
    width: 100px !important;
    text-align: right;
}

.varianceNotesColumn{
    width: calc(100vw - 40px - 280px - (8 * 117px));
}

.utilColumn{
    background-color: lightgrey;
}

.financialEntityDetails{
    position: absolute;
    top: 11px;
    right: 13px;

    background: #FFFFFF;
    box-shadow: 0 4px 2px rgba(4, 68, 77, 0.05);
    border-radius: 4px;
    border: 1px solid rgba(102, 112, 133, 0.3);
    padding: 4px 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #0D666D;

    display: none;

    .showDetailsButton & {
        display: block !important;
    }
}
