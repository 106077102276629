@import '../../colors.scss';
@import "../../fonts.scss";

.financial-metrics-icon,
.operational-metrics-icon {
    font-size: 23px;
}

.fm-table {
    width: 100%;
    background-color: $component-bg;
    border-radius: 12px;
}

.header-table th,
.header-table tr .header-table td {
    border: 1px solid var(--bg-grey);
    border-right-color: var(--white);
    padding-left: 10px;
    font-size: 12px;
}

.fm-body tr,
.fm-body td {
    border: 1px solid var(--bg-grey);
    height: 40px;
    padding-left: 12px;
    font-size: 12px;
}

.fm-tab.active {
    color: $text-tab-active;
}

.fm-tab {
    color: $text-tab-inactive;
}

.metrics {
    font-size: 14px;
}

.metrics-header {
    margin-bottom: 23.5px;
}

.financial-metrics {
    margin-right: 31px;
}

// .financial-metrics-container {
//     padding-top: 10px;
// }

.fm-dropdown-items {
    font-size: 12px;
}

.btn-fm-dropdown {
    font-weight: 600 !important;
    color: var(--grey-20) !important;
    font-family: Inter !important;
    white-space: normal !important;
    word-break: break-word !important;
    text-align: start !important;
    padding-left: 1px !important;
    font-size: 12px !important;
    margin: 0;
    margin-top: 5px !important;
    outline: 0;
    // overflow: hidden !important;
    // white-space: nowrap !important;
    // text-overflow: ellipsis !important;
    // max-width: 90px !important;
}

.btn-fm-dropdown:focus {
    outline: 0;
}

.btn-fm-dropdown:after {
    display: none !important;
}

.btn-fm-dropdown:hover {
    overflow: visible;
    white-space: normal;
}

.table-wrapper {
    border-radius: 12px;
}

.fm-td {
    width: 70px;
    padding-left: 12px;
    padding-right: 12px;
}

.fm-th {
    padding-bottom: 8px;
}

.fm-header-title {
    text-align: center;
}