.account-header-cell {
    width: 100%;
    align-items: center;
    padding-left: .75rem;
    border: 1px solid var(--bg-grey);
}

.account-header-0 {
    padding-left: 24px;
    cursor: pointer;
}

.account-header-1 {
    padding-left: 36px;
}

.account-header-2 {
    padding-left: 48px;
}

.account-link {
    text-decoration: none;
    vertical-align: baseline;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding-right: 8px;
}

// //NEED TO REMOVE THE BORDER BOTTOM FROM CATEGORIES AND ACCOUNTS
// .account-header td, .account-header th, .account-header tr { //NOT APPLYING
//     border-bottom: 1px solid white !important;
// }
