.item {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: background-color .25s ease-in-out;
    height: 3rem;
    border-bottom: 1px solid var(--grey-100);

    &:hover {
        background-color: rgba(13, 102, 109, 0.08);
    }

    button:first-child {
        margin-right: 0.5rem;
    }
    button:not(:first-child) {
        min-width: 3rem;
    }
}

.wrapper {
    padding: .5rem;
    padding-top: 100px;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 0 auto;
}
