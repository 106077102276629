$btn-border-radius: 4px;

.btn {
    height: 42px;
}

.btn-primary {
    @include button-variant($background: map-get($theme-colors, "primary"),
        $border: map-get($theme-colors, "primary"),
        $color: map-get($theme-colors, "white"),
        $hover-background: map-get($theme-colors, "primary"),
        $hover-border: map-get($theme-colors, "primary"),
        $hover-color: map-get($theme-colors, "white"),
        // $active-background: map-get($theme-colors, "primary"),
        // $active-border: map-get($theme-colors, "primary"),
        $active-color: map-get($theme-colors, "white"),
        $disabled-background: map-get($theme-colors, "primary-medium-gray"),
        $disabled-border: map-get($theme-colors, "primary-medium-gray"),
        $disabled-color: map-get($theme-colors, "white"),
    );
    @include border-radius($btn-border-radius, 0);
    margin-left: 20px !important;
}

.btn-primary:hover {
    opacity: 0.8;
}

.btn-primary.active {
    opacity: 0.8;
}

.btn-primary-medium {
    @include button-variant($background: map-get($theme-colors, "primary"),
        $border: map-get($theme-colors, "primary"),
        $color: map-get($theme-colors, "white"),
        $hover-background: map-get($theme-colors, "primary"),
        $hover-border: map-get($theme-colors, "primary"),
        $hover-color: map-get($theme-colors, "white"),
        // $active-background: map-get($theme-colors, "primary"),
        // $active-border: map-get($theme-colors, "primary"),
        $active-color: map-get($theme-colors, "white"),
        $disabled-background: map-get($theme-colors, "primary-medium-gray"),
        $disabled-border: map-get($theme-colors, "primary-medium-gray"),
        $disabled-color: map-get($theme-colors, "white"),
    );
    @include border-radius($btn-border-radius, 0);
    height: 32px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 14px 8px 14px;
    min-width: 46px;
    border-radius: 4px;
}

.btn-primary-medium:hover {
    opacity: 0.8;
}

.btn-primary-medium.active {
    opacity: 0.8;
}

.btn-primary-small {
    @include button-variant($background: map-get($theme-colors, "primary"),
        $border: map-get($theme-colors, "primary"),
        $color: map-get($theme-colors, "white"),
        $hover-background: map-get($theme-colors, "primary"),
        $hover-border: map-get($theme-colors, "primary"),
        $hover-color: map-get($theme-colors, "white"),
        // $active-background: map-get($theme-colors, "primary"),
        // $active-border: map-get($theme-colors, "primary"),
        $active-color: map-get($theme-colors, "white"),
        $disabled-background: map-get($theme-colors, "primary-medium-gray"),
        $disabled-border: map-get($theme-colors, "primary-medium-gray"),
        $disabled-color: map-get($theme-colors, "white"),
    );
    @include border-radius($btn-border-radius, 0);
    height: 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    padding: 2px;
    padding-top: 1px;
    min-width: 46px;
    border-radius: 4px;
}

.btn-primary-small:hover {
    opacity: 0.8;
}

.btn-primary-small.active {
    opacity: 0.8;
}

.btn-secondary {
    @include button-variant($background: map-get($theme-colors, "white"),
        $border: map-get($theme-colors, "primary"),
        $color: map-get($theme-colors, "primary"),
        $hover-background: map-get($theme-colors, "secondary-medium-gray"),
        $hover-border: map-get($theme-colors, "primary"),
        $hover-color: map-get($theme-colors, "primary"),
        $active-color: map-get($theme-colors, "primary"),
        $disabled-background: map-get($theme-colors, "white"),
        $disabled-border: map-get($theme-colors, "primary-medium-gray"),
        $disabled-color: map-get($theme-colors, "primary-medium-gray"),
    );
    @include border-radius($btn-border-radius, 0);
}

.btn-danger {
    @include button-variant($background: map-get($theme-colors, "danger"),
        $border: map-get($theme-colors, "danger"),
        $color: map-get($theme-colors, "white"),
        $hover-background: map-get($theme-colors, "danger"),
        $hover-border: map-get($theme-colors, "danger"),
        $hover-color: map-get($theme-colors, "white"),
        // $active-background: map-get($theme-colors, "primary"),
        // $active-border: map-get($theme-colors, "primary"),
        $active-color: map-get($theme-colors, "white"),
        $disabled-background: map-get($theme-colors, "primary-medium-gray"),
        $disabled-border: map-get($theme-colors, "primary-medium-gray"),
        $disabled-color: map-get($theme-colors, "white"),
    );
    @include border-radius($btn-border-radius, 0);
    margin-left: 20px !important;
}

.btn-danger:hover {
    opacity: 0.8;
}

.btn-danger.active {
    opacity: 0.8;
}

.btn-option {
    @include button-variant($background: map-get($theme-colors, "white"),
        $border: map-get($theme-colors, "off-white"),
        $color: map-get($theme-colors, "grey20"),
        $hover-background: map-get($theme-colors, "secondary-medium-gray"),
        $hover-border: map-get($theme-colors, "primary"),
        $hover-color: map-get($theme-colors, "primary"),
        $active-color: map-get($theme-colors, "primary"),
        $disabled-background: map-get($theme-colors, "white"),
        $disabled-border: map-get($theme-colors, "primary-medium-gray"),
        $disabled-color: map-get($theme-colors, "primary-medium-gray"),
    );
    @include border-radius($btn-border-radius, 0);
    text-align: left !important;
    padding-left: 12px !important;
}

.btn-option.active {
    color: var(--primary),
}

.btn-toggle {
    @include button-variant($background: map-get($theme-colors, "white"),
        $border: map-get($theme-colors, "lite-grey"),
        $color: map-get($theme-colors, "primary"),
        // $hover-background: map-get($theme-colors, "secondary-medium-gray"),
        // $hover-border: map-get($theme-colors, "primary"),
        $hover-color: map-get($theme-colors, "primary"),
        $active-color: map-get($theme-colors, "primary"),
        // $disabled-background: map-get($theme-colors, "white"),
        // $disabled-border: map-get($theme-colors, "primary-medium-gray"),
        // $disabled-color: map-get($theme-colors, "primary-medium-gray"),
    );
    border-radius: 100px;
    justify-content: center !important;
    font-size: 10px;
    height: 24px !important;
    min-width: 80px !important;
    font-weight: 500;
}


.btn-floating {
    @include button-variant($background: map-get($theme-colors, "white"),
        $border: map-get($theme-colors, "primary"),
        $color: map-get($theme-colors, "primary"),
        $hover-background: map-get($theme-colors, "secondary-medium-gray"),
        $hover-border: map-get($theme-colors, "primary"),
        $hover-color: map-get($theme-colors, "primary"),
        $active-color: map-get($theme-colors, "primary"),
        $disabled-background: map-get($theme-colors, "white"),
        $disabled-border: map-get($theme-colors, "primary-medium-gray"),
        $disabled-color: map-get($theme-colors, "primary-medium-gray"),
    );
    // @include border-radius($btn-border-radius, 0);
    border-radius: 4px !important;
    min-width: 140px !important;
    height: 32px !important;
    background: #FFFFFF !important;
    border: 1px solid var(--grey-200) !important;
    box-shadow: 0px 20px 28px rgba(4, 68, 77, 0.15) !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}