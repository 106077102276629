.bold {
    font-weight: 500 !important;
}

.medium {
    font-weight: 400 !important;
}

.accountTableWrapper {
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--grey-200);
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    z-index: 0;

    :global {
        .handsontable .wtHolder {
            max-height: 525px;
        }

        // they use textareas and inputs, instead of divs with contenteditable, so this is how I am kind of keeping it in the right spot when editing
        .handsontable input,
        .handsontable textarea {
            text-align: right;
            line-height: 4;
            padding-right: 13px;
        }
    }
}

.menuItem {
    background-color: unset;
    cursor: pointer;
}

.accountTable,
.summaryTable {
    transition: opacity .5s ease-in-out;
    max-width: 100%;
    width: 100%;
    max-height: 525px;
    overflow: auto;
    background-color: var(--white);
    position: relative;

    &.fadedTable {
        opacity: .35;
    }

    .cellBase {
        height: 48px;
        color: var(--grey-800);
        background-color: var(--white);
        font-weight: 400;
        font-size: .75rem;
        padding: 0 .75rem;
        vertical-align: middle;
        border-color: var(--grey-100);
        text-align: right;
        overflow: hidden;
        border-right: 1px solid var(--grey-100);
        border-bottom: 1px solid var(--grey-100);

        &.cellBorderBottom {
            border-bottom-width: 2px;
        }

        &.cellWeightMedium {
            font-weight: 500;
        }

        &.cellColorBlue {
            color: var(--royal-500);
            border-bottom-color: var(--royal-300);

            &:global(.current) {
                background-color: var(--royal-25);
            }

            &:global(.area) {
                background-color: var(--royal-25);

                &::before {
                    opacity: 0;
                }
            }
        }

        &.cellColorGreen {
            color: var(--primary-500);
            border-bottom-color: var(--primary-300);

            &.parentRowDriverCellGrouping {
                background-color: var(--primary-50);
            }

            &:global(.current) {
                background-color: var(--primary-100);
            }

            &:global(.area) {
                background-color: var(--primary-100);

                &::before {
                    opacity: 0;
                }
            }
        }

        &.cellColorMagenta {
            background-color: var(--magenta-50);
            color: var(--magenta-500);
            border-bottom-color: var(--magenta-300);

            &:global(.current) {
                background-color: var(--magenta-100);
            }

            &:global(.area) {
                background-color: var(--magenta-100);

                &::before {
                    opacity: 0;
                }
            }
        }

        &.cellBgGrey {
            background-color: var(--grey-50);
        }

        &.cellBgWhite {
            background-color: var(--white);
        }

        &.cellDisabled {
            color: var(--grey-400);
            background-color: var(--bg-grey);
        }

        &.rowHeader {
            background-color: var(--white);
            text-align: left;
            font-weight: 500;
            padding-left: .75rem;
        }

        &.dividerLine {
            background-color: var(--grey-50);
            border-right-color: var(--grey-50);
        }

        &.firstVersionRow {
            box-shadow: 0 2px var(--grey-200) inset;
        }

        &.cellAverage {
            position: relative;

            &::after {
                content: "AVG";
                color: var(--grey-400);
                font-weight: 500;
                position: absolute;
                left: .5rem;
                font-size: .625rem;
            }
        }

        &.parentRow {
            height: 48px;
            transition: background-color .25s ease-in-out;
            position: relative;
            cursor: pointer;

            &:hover {
                background-color: var(--lavender-50);
            }

            &:active {
                background-color: var(--lavender-200);
            }

            &::before {
                content: "+";
                color: var(--grey-400);
                font-weight: 500;
                margin-right: .5rem;
                font-size: 1rem;
            }

            &.parentRowVisible::before {
                content: "-";
                margin-right: .7rem;
            }
        }

        &.childRow {
            padding-left: 2rem;
        }

        &.alignLeft {
            text-align: left;
        }

        &.tallCell {
            padding-top: 2.5rem;
            padding-bottom: .5rem;
        }

        &.headerWithLabels {
            vertical-align: bottom;
        }

        &.verticalThickBorderLeft {
            border-left: 2px solid var(--grey-300);
        }

        &.verticalThickBorderRight {
            border-right: 2px solid var(--grey-300);
        }

        &.lightText {
            color: var(--grey-400);
        }

        &.hasLabel {
            &::before {
                content: "Label";
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                padding: 0.2rem 0.3rem;
                line-height: 1;
                border-radius: 2px;
                color: var(--grey-600);
                font-weight: 600;
                font-size: .625rem;
                background-color: var(--grey-100);
            }

            &.customUnitType::before {
                content: "Custom Unit Type";
            }
        }
    }

    .tableHeaderLightLabel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 72px;
        color: var(--grey-500);
        line-height: 1.5;

        span {
            font-size: .625rem;
            display: block;

            &:last-child {
                font-size: .75rem;
                font-weight: 500;
            }
        }
    }
}

.summaryTable {
    .cellBase {
        &.childRow {
            padding-left: 2.5rem;
        }
    }

    .tableHeaderLightLabel {
        height: 47px;
        justify-content: space-between;
        margin: 12px 0 13px 0;
    }
}