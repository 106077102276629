.overridenValue {
    background-color: var(--magenta-50) !important;
    color: var(--magenta-500) !important;
    border-bottom-color: var(--magenta-300) !important;
}

.editableCell {
    color: var(--primary-500);
    font-weight: 500;
    border-bottom-color: var(--primary-300) !important;
}

.uselessAdjustmentValue {
    background-color: var(--grey-50) !important;
}

.editableCellReadonly {
    background-color: var(--grey-100) !important;
}

.growthBlockTop {
    border-top: 4px solid var(--grey-200) !important;
}

.growthBlockBottom {
    border-bottom: 4px solid var(--grey-200) !important;
}

.forecastedBlockLeft {
    border-left: 2px solid var(--primary-500) !important;
}

.tableWrapper {
    table {
        thead {
            tr {
                th {
                    vertical-align: middle;
                    height: 50px;
                    background-color: white !important;
                    font-weight: 600;
                    text-align: center;
                }
            }
        }
        tbody {
            tr {
                td:first-child {
                    font-weight: 600;
                    color: black;
                }
            }
        }
    }
}