@import "../../../colors";

.passwordWrapper {
    padding-top: 17vh;

    .passwordWrapperHeader {
        margin: 1em 0 1.5em 0;
    }

    .buttonContainer {
        display: flex;
        justify-content: end;
    }

    .setPasswordButton {
        margin-top: 0.5em;
    }

    .passwordRequirementsList {
        list-style: none;
        padding: 0;
    }

    .passwordRequirementIcon {
        margin-right: 0.25em;
        position: relative;
        top: -0.1em;

        .bad {
            color: var(--negative-color);
        }

        .good {
            color: var(--positive-color);
        }
    }

    .passwordSetError {
        margin: 0.5em 0;
        color: var(--negative-color);
    }
}

.passwordRight {
    background-color: var(--primary-500);
    height: 100vh;
    padding-top: 10vh;
}