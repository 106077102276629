.wrapper {
    padding: .5rem;
    padding-top: 100px;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 0 auto;


    .inputBox {
        width: 30rem;
        float: left;
    }

    .reportTablesTableWrapper {
        max-height: 500px;
        overflow-y: auto;
    }

    .reportTablesTable {
        width: 100%;
        table-layout: fixed;
        font-size: .75rem;
    }

    .cell {
        padding: 1rem;
        border-right: 1px solid var(--grey-100);
        border-bottom: 1px solid var(--grey-100);

        .content {
            font-weight: 500;
            font-size: 12px;
            font-family: Inter;
            color: black;
            user-select: text;
    
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
    
            .controls {
                margin-right: 0;
                margin-left: auto;
            }
        }

    }

    .editForm {
        > :not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }

}