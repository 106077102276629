
.renoTable{
    position: relative;
    display: block;
    border: 1px solid #D7DCDE;
    background: white;
    width: fit-content;
    margin:0 auto;
}

.budgetYearBtn {
    border-radius: 4px;
    font-family: Inter;
    color: #09474C;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
}

.budgetYearBtn:disabled {
    border-radius: 4px;
    font-family: Inter;
    color: #09474C;
    font-size: 12px;
    font-weight: 500;
    background: var(--primary-100);
    padding: 10px 20px 10px 20px;
    text-decoration: none;
}

.budgetYearBtn:hover {
    background: #FFFFFF;
    text-decoration: none;
}
