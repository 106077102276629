.tooltipModal {
    padding: 0 !important;
    width: auto !important;
}

.tooltipModalBody {
    padding-top: 0 !important;
}

.menuWrapper * {
    opacity: 1 !important;
    pointer-events: initial !important;
    position: relative !important;
    transform: none !important;
}

.inputWrapper::after {
    color: var(--grey-200);
    position: absolute;
    top: 50%;
    left: 26px;
    transform: translateY(-50%);
}

.pseudoDollar::after {
    content: '$';
}

.pseudoPercent::after {
    content: '%';
}

.autoComplete {
    display: flex;
    input {
        caret-color: var(--grey-800);
    }
    svg {
        display: none;
    }
    // Hides selected menu items from Multiselect input field
    div div {
        display: none;
    }
}

.menu {
    border: none !important;
    box-shadow: none !important;
    margin-top: 0 !important;
    max-height: 350px !important;
    padding: 12px;
}

.menuItem {
    padding-left: 36px;
    &:hover {
        background-color: #F3F7F8;
    }
}

.disabledItem {
    display: flex;
    flex-direction: column;
    .alreadyAppliedText {
        font-size: 0.75rem;
        text-wrap: wrap;
    }
}

.staticItem {
    color: #2f3941 !important;
    display: flex;
    pointer-events: none;
    &:hover {
        background-color: inherit;
    }
}

.truncatedItem {
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.triggerNode {
    display: inline;
    font-family: inherit;
    a {
        font-family: inherit;
    }
}

.lineItemsTriggerNode {
    display: inline-block;
    font-family: inherit;
    a {
        font-family: inherit;
    }
    * {
        display: inline-block;
    }
}

.lineItemsMenuTitle {
    font-weight: 500;
    line-height: 20px;
    padding: 12px 12px 0;
}

.valContainerIcon {
    display: flex;
    flex-direction: column;
}

.valContainerInputWrapper {
    cursor: text;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.emptyCheckmarkSpacer {
    width: 16px;
}

.focusedNode {
    background-color: rgba(13, 102, 109, 0.2);
    cursor: pointer;
    // Override deletetableNode's hover styles
    &:hover {
        background-color: rgba(13, 102, 109, 0.2);
    }
}
