.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.ghostPadding {
    padding: 8px 0 8px 32px;
}

.radioRow {
    gap: 16px;
    padding: 6px 16px;
    .inputCol {
        width: 155px;
    }
}

.basePeriodRadioRow {
    gap: 0;
    .radioCol {
        margin-right: 16px;
        margin-top: -2px;
    }
}

.radioCol {
    align-self: center;
}

.menuHeader {
    border-bottom: 1px solid var(--grey-200);
    font-weight: 600;
    margin-bottom: 10px;
    padding: 16px;
}

.monthPickerTarget {
    color: var(--grey-500);
    display: flex;
    .monthPickerTargetChild {
        overflow: visible !important;
        position: absolute !important;
        top: 25px !important;
    }
    .chevDown {
        display: inline-flex;
        margin-left: 4px;
        margin-top: -2px;
    }
}

.menuWrapper > :first-child {
    margin-top: 0 !important;
    position: relative !important;
    transform: none !important;
    width: 100% !important;
}

.lookbackWrapper > :nth-child(2) {
    margin-top: 43px;
    position: absolute !important;
}

.lookbackMenu {
    display: flex;
    width: 78px !important;
    ul {
        display: flex;
        flex-direction: column;
    }
    li {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 16px;
        padding: 8px 16px;
        div, span {
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
        }
    }
}

.adjustmentWrapper {
    padding: 0 0 10px;
}

.dateRangeLabel {
    align-self: center;
}

.dollarInput::after {
    color: var(--grey-200);
    content: '$';
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

.percentInput::after {
    color: var(--grey-200);
    content: '%';
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}
