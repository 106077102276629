.wrapper {
    padding: .5rem;
    padding-top: 100px;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 0 auto;

    .propertySelect {
        width: 30rem;
        margin-left: auto;
        margin-right: 0.5rem;
    }

    .updaterControlsCard {
        background-color: var(--grey-50);
        margin-bottom: 1.5rem;
        padding: 0 1rem 1rem 1rem;
    }

    .updaterControlsSteps {
        margin: 0;
        padding-left: 1rem;

        > li {
            line-height: 1.5rem;
            margin-bottom: 1rem;
        }
    }

    .updaterControlsTitle {
        height: 60px;
        padding: 0;
        margin-bottom: 1rem;
    }

    .updaterControlsButtons {
        margin-left: auto;

        .button {
            margin-left: 1rem;
        }
    }

    .tableTopControls {
        display: flex;
        flex-direction: row;
        column-gap: 0.5rem;
    }

    .propertyTableWrapper {
        max-height: 500px;
        overflow-y: auto;
    }

    .propertyTable {
        width: 100%;
        table-layout: fixed;
        font-size: .75rem;
    }

    .propertySearch {
        width: 200px;
        float: right;
    }

    .propertyDropdownSelectAll {
        margin-top: 1rem;
        margin-left: 1.25rem;
    }

    .propertyDropdownTags {
        pointer-events: none;
        float: right;

        .tag {
            width: 100px;
            margin-left: 1rem;
            opacity: 0;

            &.isActive {
                opacity: 1;
            }
        }
    }

    .propertyDropdownItem {
        border-bottom: 1px solid var(--grey-75);

        &:hover {
            background-color: var(--grey-50);
        }
    }

    .cell {
        padding: 1rem;
        border-right: 1px solid var(--grey-100);
        border-bottom: 1px solid var(--grey-100);

        &.nameCell {
            width: 8rem;
        }

        .propertyNameCell {
            display: flex;
            flex-direction: row;
            column-gap: 0.5rem;
            align-items: center;
        }

        &.periodCell {
            text-align: center;
            width: 3rem;
        }

        .headerCell {
            .cell {
                position: sticky;
                top: 0;
                font-weight: 500;
                background-color: var(--grey-50);
                padding-top: 2rem;
            }
        }
    }
}