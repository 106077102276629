.userForm {

    > :not(:last-child) {
        margin-bottom: 1.25rem;
    }
}

.userFormLabel {
    display: flex;

    span:first-child {
        flex-grow: 1;
    }

    .required {
        font-weight: 400;
        color: var(--grey-400);
    }
}

.bulkEditForm {

    > :not(:last-child) {
        margin-bottom: 2rem;
    }

    .bulkEditHeader {
        display: flex;
        flex-direction: row;
        margin-bottom: 1em;

        .bulkEditHeaderToggle {
            margin-left: 1%;
        }
    }

    .bulkEditField {
        margin: 0 1em;
    }
}