.sectionNav{
    display: flex;
    flex-direction: row;
    gap: 12px;

    padding: 0 40px 20px 40px;

    .financials {
        display: block;
        width: 122px;
        height: 40px;
        border: none;
        background-color: #f5f7f7;
        text-indent: -9999em;
        &Btn{
            background-image: url('../../../assets/images/analyst/tab_financials_off.png');
        }
        &Img{
            background-image: url('../../../assets/images/analyst/tab_financials_on.png');
        }
    }

    .opDrivers {
        display: block;
        width: 183px;
        height: 40px;
        border: none;
        text-indent: -9999em;
        background-color: #f5f7f7;
        &Btn{
            background-image: url('../../../assets/images/analyst/tab_op_drivers_off.png');
        }
        &Img{
            background-image: url('../../../assets/images/analyst/tab_op_drivers_on.png');
        }
    }

    .renovations {
        display: block;
        width: 137px;
        height: 40px;
        border: none;
        text-indent: -9999em;
        background-color: #f5f7f7;
        &Btn{
            background-image: url('../../../assets/images/analyst/tab_renovations_off.png');
        }
        &Img{
            background-image: url('../../../assets/images/analyst/tab_renovations_on.png');
        }
    }

    .payroll {
        display: block;
        width: 101px;
        height: 40px;
        border: none;
        text-indent: -9999em;
        background-color: #f5f7f7;
        &Btn{
            background-image: url('../../../assets/images/analyst/tab_payroll_off.png');
        }
        &Img{
            background-image: url('../../../assets/images/analyst/tab_payroll_on.png');
        }
    }

    .propertiesSelector {
        display: block;
        margin-right: 0px;
        margin-left: auto;
        max-width: 252px;
        padding-right: 6px;
    }
}
