.sidebar {
    background-color: var(--white);
    width: 300px;
    height: 100vh;
    flex-shrink: 0;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    z-index: 80;
    top: 70px;
    left: 0;
    position: absolute;
    animation: slideIn 0.2s linear;
}
.sidebar-opened{
    animation: unset;
}
.sidebar-components a {
    color: var(--grey20);
}
.sidebar-components:hover a {
    color: var(--grey20);
}
.sidebar-node-children a {
    color: var(--grey20);
}
.sidebar-node-children:hover a {
    color: var(--grey20);
}


@keyframes slideIn {
    0% {
        visibility: visible;
        transform: translate(-300px, 0);
    }
    100% {
        transform: translate(0);
        visibility: visible;
    }
}
