.detailsRow{
    position: relative;
}

.detailsBody{
    display: flex;
    background-color: var(--lavender-25);
    height: 100%;
}

.detailsCell{
    position: relative;
    padding: 0 !important;
    background-color: var(--lavender-25);
}

.leftCellBorder {
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: var(--lavender-300);
    content: " ";
}

.detailsColumn {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 10px 20px 16px 20px;

    &Left {
        @extend .detailsColumn;
        border-right: 1px solid var(--grey-200);
        width: 60%;
    }

    &Right {
        @extend .detailsColumn;
        height: 100%;
        width: 40%;
    }
}
