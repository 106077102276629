.employeePageWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 768px;
    margin: 1em auto 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px; // allow room at the bottom to scroll and see last item when selection popup is active
    position: relative;

    .employeePageInner {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 300px);
        max-height: calc(100vh - 300px);

        .employeeTableCheckbox {
            top: -1rem;

            &:before,
            svg {
                width: 22px;
                height: 22px;
            }
        }

        .tableHeaderActions {
            display: flex;
            vertical-align: center;
            align-items: center;
            border: 1px solid var(--grey-200);
            border-radius: 4px 4px 0 0;
            padding: 1rem 1.5rem;

            h5 {
                flex-grow: 1;
                margin-bottom: 0;
            }

            span {
                margin-left: 1rem;
                border-left: 2px solid var(--grey-200);
                padding-left: 1rem;
                line-height: 40px;
            }

            .tableHeaderActionsLeft {
                color: var(--grey-600);

                svg {
                    margin-left: 1rem;
                }
            }

            .tableHeaderActionsRight {
                display: flex;
                align-items: center;

                .tableHeaderActionsSearch {
                    width: 350px;
                    align-self: center;
                    padding: .3rem 0;
                    margin-right: 1rem;

                    svg {
                        font-size: 1.5rem;
                    }
                }

                .tableHeaderActionsButton {
                    margin-left: 1rem;
                }
            }
        }

        .employeeTableWrapper {
            border: 1px solid var(--grey-200);
            overflow: auto;
            height: calc(100vh - 200px);
        }

        .employeeTable {
            border-top: none;
            position: relative;
            height: 100%;
            overflow: auto;
            will-change: transform;
            direction: ltr;

            td, th {
                border-left: 1px solid var(--grey-100);
            }

            th {
                font-weight: 600;
                padding-top: 1rem;
                padding-bottom: 1rem;
                vertical-align: bottom;
            }

            * {
                white-space: nowrap;
                font-weight: 500;
                font-size: .75rem;
                vertical-align: middle;
            }

            .headerRowAbove {
                height: auto;
                padding: 0;
                border-bottom: none;
                border-top: none;

                th {
                    padding: 0;
                    background-color: var(--grey-50);
                    text-align: center;
                    height: auto;
                }

                .baseCompHeader,
                .raisesHeader,
                .bonusesHeader,
                .overtimeHeader,
                .benefitHeader,
                .taxAndInsuranceHeader {
                    background-color: var(--primary-75);
                    color: var(--grey-500);
                    vertical-align: middle;
                }

                .baseCompHeader {
                    width: 62rem;
                }

                .raisesHeader {
                    width: 52rem;
                }

                .bonusesHeader {
                    width: 52rem;
                }

                .overtimeHeader {
                    width: 54rem;
                }

                .benefitHeader {
                    width: 32rem;
                }

                .taxAndInsuranceHeader {
                    width: 42rem;
                }
            }

            .headerRow {
                background-color: var(--grey-50);
                height: auto;
                vertical-align: bottom;
                align-items: end;

                th {
                    padding-top: 0.5em;
                    padding-bottom: 0.5em;
                }

                .dateCell,
                .valueCell {
                    background-color: var(--lavender-50);
                }

                .headerAuthCell {
                    white-space: normal;
                    min-width: 150px;
                    max-width: 150px;
                }

                .numberCell,
                .employeeIdCell,
                .checkboxCell,
                .statusCell,
                .positionTypeCell,
                .nameCell,
                .workClassificationCell,
                .propertyCell {
                    background-color: var(--grey-50);
                    vertical-align: top;
                }

                .dateCellMain,
                .dateCell,
                .valueCell {
                    vertical-align: top;
                }

                th {
                    z-index: 10;
                }
            }

            .bodyRow {
                td {
                    background-color: white;
                    padding: 0.5em 1em;
                }

                &:hover td {
                    background-color: var(--primary-50);
                }

                &:hover .nameCell {
                    .editButton {
                        display: block;
                    }
                }
            }

            // the following cell definitions are for ensuring the stick columns maintain correct position
            .checkboxCell {
                position: sticky;
                width: 50px;
                min-width: 50px;
                max-width: 50px;
                left: 0;
                border-right: none;
                border-left: none;
            }

            .numberCell {
                position: sticky;
                width: 50px;
                min-width: 50px;
                max-width: 50px;
                left: 50px;
                border-right: none;
                border-left: none;
            }

            .nameCell {
                position: sticky;
                left: 100px;
                box-shadow: -2px 0 0 0px var(--grey-200) inset,
                            1px 0 0 0px var(--grey-100) inset;
            }

            .nameCell {
                border-left: none;
                width: 300px;
                min-width: 300px;
            }

            .nameCell {
                .editButton {
                    display: none;
                    margin-right: 0;
                    margin-left: auto;
                    line-height: 20px;
                    height: 20px;
                }
            }

            .employeeIdCell {
                width: 7rem;
            }

            .statusCell {
                width: 5rem;
            }

            .positionTypeCell {
                width: 15rem;
            }

            .workClassificationCell {
                width: 10rem;
            }

            .propertyCell {
                width: 20rem;
            }

            .dateCellMain,
            .dateCell {
                width: 12rem;
            }

            .valueCell {
                width: 10rem;
            }
        }
    }
}