.budget-button,
.annual-button,
.yoy-button {
    font-size: 14px;
    cursor: pointer;
    padding-right: 25px;
    border: none;
    background-color: var(--bg-grey);
}

.dashboard-title {
    text-align: center;
    padding-top: 90px;
    padding-bottom: 15px;
}

.dashboard-toggle {
    display: flex;
    align-items: center;

    .dashboard-toggle__fetch-button {
        margin-right: 1rem;
    }
}

.dashboard-text {
    font-size: 24px;
    font-weight: bold;
    color: var(--darkliver);
}

.dashboard {
    padding-left: 40px;
    padding-right: 40px;
}

.as-dashboard-header {
    max-width: 1427px;
    margin: auto;
}

.yoy-dashboard-header {
    max-width: 1650px;
    margin: auto;
}