.perfDashContainer {
    max-width: 1366px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin: 84px auto 0 auto;
    height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    padding: 0 20px 0 20px;

    * {
        min-height: unset;
    }
}

.perfDashBody {
    display: flex;
}

.overviewCardsRow {
    display: flex;
    column-gap: 12px;
    padding-top: 12px;

    div {
        flex-grow: 1;
    }
}

.dashboardRow {
    padding-top: 1rem;

    >div {
        flex-grow: 1;
    }
}

.widgetTable {
    .valueColumn {
        min-width: 100px !important;
        padding: 10px !important;
        font-size: 0.75rem !important;
    }

    .valueColumnNarrow {
        text-align: right !important;
        padding: 10px !important;
        font-size: 0.75rem !important;
    }

    .summaryRow {
        background-color: var(--grey-50);
        font-size: 0.75rem !important;
        font-weight: 600 !important;
        padding: 10px !important;
        text-align: right !important;
    }

    .positiveChange {
        text-align: right;
        color: var(--positive-color);
    }

    .negativeChange {
        text-align: right;
        color: var(--negative-color);
    }

    .leftAlignCell {
        text-align: left !important;
    }
}

.widgetLabel {
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--grey-500);
    text-transform: uppercase;
    padding-bottom: 0.5rem;
}

.widgetBody {
    border: 1px solid var(--grey-300);
    border-radius: 8px;
}