.jobListWrapper {

    .table {
        table-layout: auto;
        margin-bottom: 1rem;

        .headerRow {
            background-color: var(--grey-50);
        }

        .tBody {
            position: relative;
        }

        td {
            vertical-align: middle;
            max-width: 0;
        }

        .brandV {
            width: 16px;
            margin-right: .25rem;
        }

        .statusIconCell {
            width: 20px;
            border-bottom: 0;
            text-align: center;
        }
    }
}

.jobDetailsButton {
    background-color: var(--primary-50) !important;

    .jobDetailsButtonContents {
        display: flex;
        align-items: center;
    }

    .jobDetailsButtonArrow {
        font-size: .75rem;
        margin: 0 .25rem;
    }
}

.jobDetailsModal {
    width: 50vw !important;
}

.jobDetailsModalBody {
    position: relative;
}

.jobDetailsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .25rem;

    .title {
        margin-bottom: 0;
    }

    .periodArrow {
        font-size: .75rem;
        margin: 0 .25rem;
    }
}

.jobDetailsSubtitle {
    color: var(--grey-500);
}

.jobDetailsBody {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    border-top: 1px solid var(--grey-100);
    padding-top: .75rem;
    gap: .5rem;
}

.jobDetailsListWrapper {
    flex-basis: 100%;
}

.jobDetailsListHeader {
    display: flex;
    align-items: center;
    gap: .25rem;
    justify-content: center;
}

.jobDetailsList {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    list-style: none;
    background-color: var(--primary-50);
    border-radius: 4px;
    height: 200px;
    overflow-y: auto;
    padding: 1rem;
    gap: .5rem;
    margin-top: .5rem;

    li {
        font-weight: 500;
        display: flex;
        flex-direction: column;

        span {
            font-weight: 400;
            font-size: .7rem;
        }
    }
}
