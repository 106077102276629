.viziblyApexChartTooltip {
    min-width: 200px;
    padding-bottom: .5rem;
    color: var(--grey-800);

    .tooltipHeader {
        padding: .5rem;
        font-weight: 600;
        background-color: var(--grey-100);
        border-bottom: 1px solid var(--grey-200);
    }

    .tooltipValue {
        padding: 0 .5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tooltipValueColorIndicator {
            width: .75rem;
            height: .75rem;
            max-width: .75rem;
            max-height: .75rem;
            border-radius: 100%;
            margin-right: .5rem;
        }

        .tooltipValueLabel {
            font-weight: 500;
        }

        .tooltipValueAmount {
            padding-left: 2rem;
            text-align: right;
            font-weight: 500;
        }
    }

    .tooltipDivider {
        background-color: var(--grey-300);
        margin: .5rem;
    }

    .tooltipVariance {
        padding: 0 .5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tooltipVarianceLabel {
            color: var(--grey-400);
            font-weight: 500;
        }

        .tooltipVarianceAmount {
            text-align: right;
            color: var(--grey-800);
            font-weight: 500;
        }
    }
}