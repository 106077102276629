.accountSummaryWrapper {
    padding: 0 2.8rem 0 0;
    min-width: 1260px;
    max-width: 1366px;
    margin: 0 auto;

    .tabListNoMargin {
        margin-bottom: 0;
    }

    .accountSummary {
        padding: 0;
    }

    .accountSummaryColumn {
        height: 337px;
        padding-bottom: 1rem;
    }

    .accountSummaryTitle {
        display: flex;
        justify-content: space-between;
        align-items: self-end;
    }

    .accountSummarySubtitle {
        display: flex;
        justify-content: start;
        margin-top: 12px;
        align-items: baseline;

        .accountSummarySubtitleSeparator {
            margin: 0 6px 0 3px;
            font-weight: 500;
            color: var(--grey-300);

            &.accountSummarySubtitleSeparatorIcon {
                position: relative;
                top: -2px;
                left: -2px;
                transform: rotateY(180deg);
            }
        }
    }

    .accountSummaryHeaderText {
        font-size: 36px;
        font-weight: 600;
    }

    .accountSummaryBodyWide {
        display: flex;
        height: 250px;
        padding-top: 0 !important;

        .accountSummaryBodyWideLeftWrapper {
            width: 170px;
            flex-shrink: 0;
            flex-grow: 0;
            align-self: center;

            .accountSummaryBodyWideLeft {
                .statLabel {
                    margin-top: 12px;
                }

                .statValue {
                    margin-bottom: 12px;
                }
            }
        }

        .accountSummaryBodyWideRight {
            flex-grow: 1;
        }
    }
}

.statLabel {
    font-weight: 600;
    font-size: 12px;
    color: var(--grey-500);
    margin-bottom: 6px;

    &:last-child {
        text-align: right;
    }
}

.statLabelLight {
    color: var(--grey-300);
}

.statValue {
    font-weight: 600;
    font-size: 18px;
    color: var(--grey-800);
    margin-bottom: 0;
}

.statValueMedium {
    margin-top: 14px;
    font-size: 16px;
}

.statGreen {
    color: var(--positive-color);
}

.statRed {
    color: var(--negative-color);
}