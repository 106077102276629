.valueInput {
    width: 3rem;
    text-align: right;
}

$pms-color: var(--royal-25);
$current-forecasted-color: var(--primary-50);

.tableWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 768px;
    height: 500px;
    position: relative;
    overflow-y: scroll;
}

.valuesTable {
    min-width: 768px;
    max-width: 1366px;

    thead {
        border-bottom: 2px solid var(--grey-400);
    }
    
    tbody {
        tr:nth-child(4n+2) {
            background-color: $pms-color;
        }
        tr:nth-child(4n+3) {
            background-color: $current-forecasted-color;
        }
        tr:nth-child(4n+4) {
            border-bottom: 2px solid var(--grey-400);
        }
        td:not(:first-child) {
            text-align: right;
        }
        tr {
            height: 1.5rem;
            vertical-align: middle;
        }
        td {
            border-left: 1px solid var(--lighter-grey);
            padding-top: 0;
            padding-bottom: 0;
        }
        td.keepCurrent {
            text-align: center;
            background-image: linear-gradient(
                45deg,
                var(--grey-75) 14.29%,
                #ffffff 14.29%,
                #ffffff 50%,
                var(--grey-75) 50%,
                var(--grey-75) 64.29%,
                #ffffff 64.29%,
                #ffffff 100%
            );
            background-size: 7.00px 7.00px;
        }
        td:last-child {
            border-right: 1px solid var(--lighter-grey);
        }
        td:first-child {
            min-width: 30rem;
            button {
                line-height: 1rem;
                height: 1rem;
                padding-left: 0;
            }
        }
    }
}

.unitTypeName {
    font-weight: 600;
}

.valueColumn {
    width: 4rem
}

.valuesModal {
    width: 80rem !important;
}

.valuesModalBody {
    padding-top: 0;
    margin-top: 20px;
}

.notavailable {
    background-color: var(--grey-100);
}

.footerWrapper {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
}

.buttons {
    margin-right: 0;
    margin-left: auto;
}

.legendPms {
    background-color: $pms-color;
}

.legendCurrentForecasted {
    background-color: $current-forecasted-color;
}

.legendNewForecasted {
    background-color: white;
}

.legendPms,
.legendCurrentForecasted,
.legendNewForecasted {
    border: 1px solid var(--grey-400);
    line-height: 30px;
    min-width: 90px;
    text-align: center;
    vertical-align: middle;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.checkbox {
    top: -0.6rem;
    left: 0.1rem;

    &:before,
    > svg {
        width: 1rem !important;
        height: 1rem !important;
    }
}