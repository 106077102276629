.wrapper {
    padding: .5rem;
    padding-top: 100px;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 0 auto;


    .inputBox {
        width: 30rem;
        float: left;
    }

    .propertySelect {
        width: 30rem;
        margin-left: auto;
        margin-right: 0.5rem;
    }

    .updaterControlsCard {
        background-color: var(--grey-50);
        margin-bottom: 1.5rem;
        padding: 0 1rem 0 1rem;
        height: 4rem;
        display: flex;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
    }

    .updaterControlsButtons {
        margin-left: auto;

        .button {
            margin-left: 1rem;
        }
    }

    .unitTypesTableWrapper {
        max-height: 500px;
        overflow-y: auto;
    }

    .unitTypesTable {
        width: 100%;
        table-layout: fixed;
        font-size: .75rem;

        tbody {
            tr {
                max-height: 1rem;
                td {
                    padding-top: 0;
                    padding-bottom: 0;
                    vertical-align: middle;
                }
            }
        }
    }

    .cell {
        padding: 1rem;
        border-right: 1px solid var(--grey-100);
        border-bottom: 1px solid var(--grey-100);

        &.nameCell {
            width: 8rem;
        }

        &.inUseCell {
            width: 3rem;
            text-align: center;
        }

        &.periodCell {
            text-align: center;
            width: 3rem;
        }

        &.metricCell {
            text-align: center;
            width: 3rem;

            &.isOverride {
                background-color: var(--magenta-100);
            }
        }

        .content {
            font-weight: 500;
            font-size: 12px;
            font-family: Inter;
            color: black;
            user-select: text;
    
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
    
            .controls {
                margin-right: 0;
                margin-left: auto;
            }

            .nameCellBody {
                display: flex;
                flex-direction: row;
                vertical-align: middle;
                column-gap: 0.5rem;
            }
        }

        .headerCell {
            .cell {
                position: sticky;
                top: 0;
                font-weight: 500;
                background-color: var(--grey-50);
                padding-top: 2rem;
            }
        }
    }

    .editForm {
        > :not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }

    .formField {
        width: 30rem;
        float: left;
        margin-bottom: 1.5rem;
    }

}

.pmsPanel {
    margin-top: 10px;
    border: 1px solid var(--grey-100);
    border-radius: 8px;
    .pmsTableFilters {
        padding: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span {
            font-weight: 600;
        }
        column-gap: 0.5rem;
    }
    .pmsTableWrapper {
        height: calc(100vh - 440px - 100px - 30px - 10rem);
        border-top: 1px solid var(--grey-100);
        overflow: auto;
        .pmsTable {
            .pmsTableHead {
                font-weight: 600;
            }
            thead {
                th:first-child {
                    button {
                        max-width: fit-content;
                    }
                }
                th:nth-last-child(3) {
                    width: 11rem;
                    text-align: right !important;
                }
                th:nth-last-child(2) {
                    width: 9rem;
                    text-align: right !important;
                }
                th:last-child {
                    width: 5.5rem;
                }
            }
            tbody {
                button {
                    padding: 0 !important;
                    height: 16px !important;
                }
                tr {
                    height: 16px;
                }
                td {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
                td:nth-last-child(3) {
                    text-align: right;
                }
                td:nth-last-child(2) {
                    text-align: center;
                }
                td:last-child {
                    text-align: right;
                }
            }
        }
    }
}


.valuesTable {
    thead {
        border-bottom: 2px solid var(--grey-400);
    }
    
    tbody {
        tr {
            height: 2rem;
            vertical-align: middle;
        }
        td {
            border: 1px solid var(--lighter-grey);
            padding: 0;
            input {
                margin-left: 0.2rem;
                margin-right: 0.2rem;
                height: 2rem;
            }
        }
        td:first-child {
            min-width: 8rem;
        }
    }
}