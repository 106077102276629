.metricCardWrapper {
    height: 100%;

    .metricCard {
        height: 100%;
        padding: 0;

        .metricCardTitle {
            padding: 14px;
            border-bottom: 1px solid var(--white-smoke);
        }

        .metricCardSubtitle {
            margin-top: 8px;
            margin-bottom: 0;
            color: var(--white-smoke);
        }

        .metricCardBody {
            padding: 16px 0 0 0;
        }
    }
}