.settingsTableWrapper {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--grey-200);
    overflow: hidden;
}

.settingsTableLoader {
    padding: 2rem;
    text-align: center;
}

.settingsTableRow {
    display: flex;
    font-weight: 500;
    font-size: .75rem;
    align-items: center;

    &:not(:last-child) {
        border-bottom: 1px solid var(--grey-100);
    }

    .settingsTableRowColumn {
        width: 50%;
        padding: 1rem;
    }
}

.settingsTableRowHeader {
    background-color: var(--grey-50);
    font-weight: 600;
}