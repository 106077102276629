@import "../../../../colors";

.dashboard {
    position: relative;
    padding-top: 63px;

    .loaderContainer {
        display: inline-block;
        width: 100vw;
        top: 75px;
        height: 6px;
    }

    .mainHeader {
        height: 75px;

        .viewTitle {
            display: inline-block;
            padding: 24px 10px 0 40px;
            font-size: 26px;
            font-weight: 500;
            color: var(--darkliver);
        }

        .betaTag {
            display: inline-block;
            position: relative;
            padding: 2px 7px 2px 8px;
            top: -3px;
            color: #FFFFFF;
            font: 500 12px Inter;
            background: #4F609B;
            border: 1px solid #4F609B;
            box-shadow: 0 2px 4px rgba(79, 96, 155, 0.3);
            border-radius: 10px;
        }
    }

    .editorButton {
        margin-right: 4px
    }

    .editorNavSection {
        padding-right: 8px;
    }

    .section {
        width: calc(100vw - 80px);
        margin: 0 auto;
        padding-top: 4px;
    }

    .tabNavRow {
        display: flex;
        margin: 0 auto;
        width: calc(100vw - 80px);

        .tabNav {
            width: calc(50vw - 40px);

            &Right {
                text-align: right;
            }

            &Button {
                margin-right: 12px;
            }

            &IconButton {
                display: inline-block;
                margin-top: 2px;
                margin-right: 8px;
                color: var(--primary-600) !important;
            }

            &RightButton {
                margin-left: 12px;
            }

            &ActiveButton {
                margin-right: 12px;
                background-color: #DAEDED !important;
                color: var(--primary-600) !important;
            }
        }
    }
}

#loaders.progress_background {
    padding: 0;
}
