.adminWrapper {
    padding-top: 100px;
    max-width: 1366px;
}

.adminTitle {
    margin-bottom: 1.5rem;
    color: var(--grey-800);
}

.adminSubtitle {
    margin-bottom: 1.5rem;
    color: var(--grey-800);
}