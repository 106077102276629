.yearMonthSelector{
    position:relative;
    z-index: 2;
}

.currentYearMonthBtn{
    display: flex;
    min-width: 164px;
    justify-content: space-between !important;
}

.yearContainer{
    position: absolute !important;
    padding: 6px !important;
    width: 220px;
}

.monthsContainer{
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2px auto 4px auto;

    .monthsButton {
        width: 60px;
    }
}

.yearNavigator{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}

.calendarLabel{
    display: flex;
    justify-content: center;

    padding: 7px 0 3px 0;
    margin-top: 10px;

    border-top: 1px solid var(--grey-100);

    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-300);
}
