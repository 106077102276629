.opDriverModelingWrapper {

    .headerDropdown {
        display: inline-block;
        min-width: 180px;

        .headerSelect {
            align-items: center;

            .dropdownLabel {
                display: block;
                color: var(--grey-400);
                font-weight: 400;
                font-size: .625rem;
            }

            svg {
                margin: 0;
            }
        }
    }

    .headerSubtitle {
        font-weight: 400;
        font-size: .75rem;
        color: var(--grey-600);
        margin-top: .75rem;
    }

    .bodyContent {
        width: 100%;
        max-width: 1366px;
        margin: 0 auto;

        >div {
            margin-bottom: 1rem;
        }
    }

    .buttonGroup :not(:last-child) {
        margin-right: 1rem;
    }

    .dropdownTableWidthLimitedCell {
        width: 250px;
    }

    .bareSelect {
        border: none;
    }

    .limitedCardWidth {
        max-width: 440px;
    }
}

.configRuleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .configRuleHeaderLeft {
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    .configRuleHeaderRight {
        font-weight: 300;
        font-size: .75rem;
        color: var(--danger);
        height: 24px;
    }
}

.configRuleField {
    display: flex;
    align-items: center;

    .configRuleFieldLabel {
        width: 270px;
        font-weight: 400;
        margin-bottom: -10px;
    }
}

.configRuleDivider {
    margin: 1rem 0;
    width: 100%;
    background-color: var(--grey-600);
}

.summaryTabNav {
    margin-bottom: 0 !important;

    .summaryTab {
        font: 400 14px Inter;
    }
}

.summaryContainer {
    .headerDropdown {
        width: 210px;
    }
}

.summaryTableWrapper {
    border: none !important;
}

.summaryWrapper {
    margin-top: 16px !important;

    .downloadBtn {
        background: #FFFFFF;
        border: none;
    }
}

.marketRentsOverrideCard {
    float: left;
    max-width: 500px;
}

.renovationsClassicColumn {
    transition: background-color .2s ease-in-out;
    display: flex;
    align-items: center;
    cursor: pointer;

    .renovationColumnEditButton {
        transition: opacity .2s ease-in-out;
        float: right;
        background-color: var(--grey-100);
        pointer-events: none;
        opacity: 0;
        text-transform: uppercase;
        height: 24px;
        margin-left: auto;
    }

    &:hover {
        background-color: var(--primary-50);

        .renovationColumnEditButton {
            opacity: 1;
        }
    }
}

.renovationDrawerTopControls {
    padding: 1rem;
    display: flex;
    background-color: var(--grey-50);
    width: 100%;

    .classicField {
        width: 150px;
    }

    .renoField {
        width: 250px;
    }

    .fakeUnitMenuItem {
        color: var(--green-500);
    }

    .fakeUnitTypeField {
        margin-left: 1rem;

        &.fakeUnitTypeFieldMarketRent {
            margin-top: 1rem;
        }

        .fakeUnitTypeFieldInputs {
            display: flex;
            margin-top: .5rem;

            .fakeUnitTypeFieldInputsLeftTrigger {
                width: 250px;
            }

            .fakeUnitTypeFieldInputsLeftTriggerMarketRents {
                width: auto;

                li {
                    padding-left: 1rem;
                }
            }

            .fakeUnitTypeFieldInputsLeft {
                width: 250px;
                display: flex;
                justify-content: space-between;

                &.fakeUnitSelected {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: 0;
                }
            }

            .fakeUnitTypeFieldInputsRight {
                width: 250px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .classicLabel {
        color: var(--grey-800);
        font-size: .875rem;
        font-weight: 600;
        position: relative;
        top: -.15rem;
    }

    .classicValue {
        font-weight: 400;
        position: relative;
        top: 1rem;
    }

    .mappingArrow {
        margin-top: 2rem;
        margin-right: 2rem;
        color: var(--grey-400);
        font-size: 1.5rem;
    }

    .renovatedUnitTypeLabel {
        width: 100%;

        .resetAssociation {
            height: 20px;
            float: right;
        }
    }
}

.card {
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    font-size: .875rem;
    color: var(--grey-800);
    overflow: hidden;

    .cardTitle {
        height: 72px;
        font-weight: 500;
        padding: 0 1rem;
        border-bottom: 1px solid var(--grey-200);
    }

    .cardActionsCol {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .accountTableWrapper {
        border: 0;
    }
}

.lockStatusTags {
    .lockStatusTag {
        padding: 1rem .75rem;
        margin-right: .5rem;
    }

    .lockStatusTagIcon {
        margin-right: .25rem;
    }
}
