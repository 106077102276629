.wrapper {
    transition: all .25s ease-in-out;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    &.isVisible {
        opacity: 1;
        visibility: visible;
    }
}

.line {
    position:absolute;
    opacity: 0.4;
    width: 150%;
    height: 3px;
}

.subline {
    position: absolute;
    height: 3px;
}

.inc {
    animation: increase 2s infinite;
}

.dec {
    animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
    from { left: -5%; width: 5%; }
    to { left: 130%; width: 100%; }
}

@keyframes decrease {
    from { left: -80%; width: 80%; }
    to { left: 110%; width: 10%; }
}
