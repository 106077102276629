.tableWrapper {
    display: flex;
    flex-direction: column;
    min-width: 1366px;
    max-width: 1440px;
    margin: auto;
    border: 1px solid var(--grey-200);

    .header {
        display: flex;
        flex-direction: row;
        height: 4rem;
        width: 100%;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;

        .label {
            font-size: 20px;
            font-weight: 500;
            color: var(--grey-600);
        }

        .controls {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            margin-left: auto;
            margin-right: 0;
        }
    }

    table {
        border-top: 1px solid var(--grey-200);
        min-width: 1366px;
        max-width: 1440px;
        margin: auto;

        th:first-child {
            width: 11rem;
        }
        td {
            font-size: .7rem;
            text-align: right;
        }
        td:first-child {
            text-align: left;
            &.unitTypeName {
                padding-left: 2rem;
            }
        }

        tbody {
            tr {
                height: 0.6rem !important;
                line-height: 0.5rem !important;
                td {
                    padding-top: 0.005 !important;
                    padding-bottom: 0.005 !important;
                }
            }
        }
    }
}

.unitTypeSelector {
    width: 11rem;
}

.increasedRowOpacity {
    opacity: 0.5;
}

.periodSplit {
    border-left: 2px solid var(--grey-200);
}

.headerCellContent {
    width: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--grey-800);
    line-height: 1.5rem;
    font-weight: 600;

    span {
        font-size: .7rem;
        line-height: .7rem;
        height: .7rem;
        min-height: .7rem;
        display: block;

        &:last-child {
            font-size: .75rem;
            font-weight: 600;
        }
    }
}