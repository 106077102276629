@import "../../colors.scss";

.workflowContainer {
    display: block;
    padding-top: 10px;
    background-color: white;
    padding-bottom: 2rem;

    .workflowContainerInner {
        margin: 0 auto;
    }

    .headerRow {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 72px;
        padding: 24px 18px;

        justify-content: space-between;

        .labelContainer {
            display: flex;
            column-gap: 5px;
            align-items: center;

            .starredAccount {
                display: inline-block;
                width: 21px;
                padding-bottom: 6px;
            }

            .mainLabel {
                font: 400 22px Inter;
                line-height: 28px;
                color: var(--grey-600);
            }
        }

        .workflowNavigation {
            display: flex;
            column-gap: 16px;
            align-items: center;
        }
    }

    .yearTabsRow {
        display: block;
        position: relative;
        padding-left: 2.5rem;

        &:after {
            content: '';
            display: block;
            height: 1px;
            width: 100vw;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--grey-200);
        }
    }

    .tabContent {
        display: flex;
        width: 100%;
        background-color: white;
        margin: 0 auto;
    }

    .tabLockIcon {
        width: .85rem;
        height: .85rem;
        margin-right: .35rem;
        margin-top: -3px;
    }

    .dataColumn {
        width: 100%;
        max-width: 1327px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 30px;

        .tableSkeletonLoader {
            width: 100%;
            height: 220px;
            position: relative;

            table {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;

                td {
                    border: 5px solid var(--white);

                    &:first-child {
                        border-left: 0;
                    }

                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
    }

    .actionsCol {
        display: flex;
        flex-direction: column;
    }

    .bodyContainer {
        position: relative;
        margin: 0 auto;

        .accountYearTabNav {
            overflow: visible;

            .accountYearTab {
                font: 400 14px Inter;
            }
        }

        .accountTableContainer {
            margin-bottom: 10px;
            align-self: center;

            &.accountSummaryTable {
                width: 100%;
            }
        }

        .accountFinancialsContainer {
            display: block;
            margin: 0 auto;
            width: 100%;
        }

        .mainAccountSheet {
            padding-bottom: 10px;
        }

        .tabsContainer {
            min-width: 1366px;
            max-width: 1440px;
            overflow: visible;
            padding: 0 2rem;
            margin: 0 auto;
        }
    }
}