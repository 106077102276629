.modal {
  width: 1409px !important;
  max-width: 95vw !important;
  min-width: min-content !important;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.accountTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.accountName {
  font-size: 18px;
  line-height: 24px;
}

.navigationButtons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.noteContainer {
  margin-top: 24px;
}

.noteContent {
  background-color: var(--grey-100);
  padding: 16px;
  border-radius: 4px;
  min-height: 100px;
  border: 1px solid var(--grey-300);
} 