.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.wrapper {
    display: flex;
    padding: 16px;
    .yearPicker {
        align-items: center;
        justify-content: space-between;
        margin: 4px 8px 16px;
    }
    .monthPicker {
        flex-wrap: wrap;
        gap: 8px;
        justify-content: space-between;
        width: 184px;
        .month {
            align-items: center;
            border: none;
            color: var(--primary-500);
            flex-basis: 21%;
            height: 40px;
            justify-content: center;
            &:disabled {
                background-color: transparent !important;
                color: var(--grey-400);
                cursor: not-allowed !important;
            }
        }
        .selectedMonth {
            background: var(--primary-500);
            border-radius: 4px;
            color: white;
        }
    }
}

.navBtn {
    padding: 0;
    &:disabled {
        background-color: transparent !important;
        cursor: not-allowed !important;
    }
}
