$theme-colors: (
    "primary": #107F88,
    "primary-25": #F8FAFB,
    "primary-30": #F1FBF7,
    "primary-50": #F3F7F8,
    "primary-75": #E5F0F0,
    "primary-100": #DBE8E9,
    "primary-200": #AAC9CC,
    "primary-300": #6EA3A7,
    "primary-400": #3D858A,
    "primary-500": #0D666D,
    "primary-600": #0A5257,
    "primary-700": #09474C,
    "primary-800": #083D41,
    "primary-900": #062E31,
    "grey-25": #FBFBFB,
    "grey-50": #F7F8F9,
    "grey-75": #F0F1F3,
    "grey-100": #E8EAED,
    "grey-200": #D1D4DA,
    "grey-300": #B3B8C2,
    "grey-400": #858D9D,
    "grey-500": #667085,
    "grey-800": #333843,
    "grey-900": #1F2228,

    "royal-25": #F0F6FF,
    "royal-50": #CEE1FE,
    "royal-75": #BAD1F5,
    "royal-100": #A5C1EB,
    "royal-200": #7DA2D9,
    "royal-300": #5482C6,
    "royal-400": #2C63B4,
    "royal-500": #0343A1,
    "royal-600": #023681,
    "royal-700": #022861,
    "royal-800": #011B40,
    "royal-900": #010D20,

    "lavender-25": #F2F3F9,
    "lavender-50": #EFF0F7,
    "lavender-75": #DFE2EF,
    "lavender-100": #CED3E8,
    "lavender-200": #BEC4E0,
    "lavender-300": #9EA7D0,
    "lavender-400": #7D89C1,
    "lavender-500": #5D6CB1,
    "lavender-600": #4A568E,
    "lavender-700": #38416A,
    "lavender-800": #1C2035,

    "magenta-25": #FCFAFB,
    "magenta-50": #F8F3F6,
    "magenta-75": #F3EAF0,
    "magenta-100": #E6D3DF,
    "magenta-200": #CDA6C0,
    "magenta-300": #B57AA0,
    "magenta-400": #9C4D81,
    "magenta-500": #832161,
    "magenta-600": #4A568E,
    "magenta-700": #4F143A,
    "magenta-800": #411030,

    "yellow-100": #FCD6A7,
    "yellow-200": #FBC687,
    "yellow-300": #FABC71,
    "yellow-400": #F9AD50,
    "yellow-500": #F79824,

    "pink-400": #E9B9D3,

    "secondary": #FF5C00,
    "white": #FFFFFF,
    "grey20": #333333,
    "bg-grey": #F5F7F7,
    "yellow": #FBB755,
    "mint": #ACE2D4,
    "danger": red,
    "green-500": #007A56,
    "red-500": #D93F4C,

    "positive-color": #007A56,
    "negative-color": #D93F4C,

    "nickel": #737373,
    "off-white": #f2f2f2,
    "darkliver": #4f4f4f,
    "grey": #7b7b7b,
    "grey3": #828282,
    "lite-grey": #e0e0e0,
    "medium-grey": #bdbdbd,
    "litesilver": #d8d8d8,
    "primary-medium-gray": #D3D3D3,
    "secondary-medium-gray": #F9F9F9,
    "linen": #fff0e4,
    "pattens-blue": #dbebea,
    "philippine-grey": #929292,
    "whisper": #ededed,
    "white-smoke": #f0f0f0,
    "pink-swan": #B7B7B7,
    "nobel": #9c9c9c,
    "blueberry": #4e95ff,
    "alice-blue": #e9f2f8,
    "lavender": #e0edf8,
    "ghost-white": #f5fbff,
    "blue-ribbon": #006ae5,
    "light-cyan": #e5f7f6,

    "azure": #EAF8F8,
    "light-azure": #F4FBFB,
    "dodger-blue": #3091EC,
    "roman": #DB5D53,
    "blush": #FFF4F3,
    "eucalyptus":#299C66,
    "mint-cream": #F4FFF7,
    "persian-green": #00B1A7,
    "glacier": #78BCBC,

    "red-100": #FFF0F1,
    "purple-700": #691A4E,
    ) !default;

:root {
    --primary: #107F88;
    --primary-25: #F8FAFB;
    --primary-30: #F1FBF7;
    --primary-50: #F3F7F8;
    --primary-75: #E5F0F0;
    --primary-100: #DBE8E9;
    --primary-200: #AAC9CC;
    --primary-300: #6EA3A7;
    --primary-400: #3D858A;
    --primary-500: #0D666D;
    --primary-600: #0A5257;
    --primary-700: #09474C;
    --primary-800: #083D41;
    --primary-900: #062E31;
    --grey-25: #FBFBFB;
    --grey-50: #F7F8F9;
    --grey-75: #F0F1F3;
    --grey-100: #E8EAED;
    --grey-150: #DDE0E4;
    --grey-200: #D1D4DA;
    --grey-300: #B3B8C2;
    --grey-400: #858D9D;
    --grey-500: #667085;
    --grey-600: #525A6A;
    --grey-700: #474E5D;
    --grey-800: #333842;
    --grey-900: #1F2228;

    --royal-25: #F0F6FF;
    --royal-50: #CEE1FE;
    --royal-75: #BAD1F5;
    --royal-100: #A5C1EB;
    --royal-200: #7DA2D9;
    --royal-300: #5482C6;
    --royal-400: #2C63B4;
    --royal-500: #0343A1;
    --royal-600: #023681;
    --royal-700: #022861;
    --royal-800: #011B40;
    --royal-900: #010D20;

    --lavender-25: #F2F3F9;
    --lavender-50: #EFF0F7;
    --lavender-75: #DFE2EF;
    --lavender-100: #CED3E8;
    --lavender-200: #BEC4E0;
    --lavender-300: #9EA7D0;
    --lavender-400: #7D89C1;
    --lavender-500: #5D6CB1;
    --lavender-600: #4A568E;
    --lavender-700: #38416A;
    --lavender-800: #1C2035;

    --magenta-25: #FCFAFB;
    --magenta-50: #F8F3F6;
    --magenta-75: #F3EAF0;
    --magenta-100: #E6D3DF;
    --magenta-200: #CDA6C0;
    --magenta-300: #B57AA0;
    --magenta-400: #9C4D81;
    --magenta-500: #832161;
    --magenta-600: #4A568E;
    --magenta-700: #4F143A;
    --magenta-800: #411030;

    --yellow-100: #FCD6A7;
    --yellow-200: #FBC687;
    --yellow-300: #FABC71;
    --yellow-400: #F9AD50;
    --yellow-500: #F79824;

    --pink-400: #E9B9D3;

    --secondary: #FF5C00;
    --white: #FFFFFF;
    --grey20: #333333;
    --bg-grey: #F5F7F7;
    --yellow: #FBB755;
    --mint: #ACE2D4;
    --danger: red;
    --green-500: #007A56;
    --red-500: #D93F4C;

    --positive-color: #007A56;
    --negative-color: #D93F4C;

    --nickel: #737373;
    --off-white: #f2f2f2;
    --darkliver: #4f4f4f;
    --grey: #7b7b7b;
    --grey3: #828282;
    --lite-grey: #e0e0e0;
    --medium-grey: #bdbdbd;
    --litesilver: #d8d8d8;
    --primary-medium-grey: #D3D3D3;
    --border-gray: #e5e5e5;
    --background-gray: #f5f5f5;
    --linen: #fff0e4;
    --pattens-blue: #dbebea;
    --philippine-grey: #929292;
    --whisper: #ededed;
    --white-smoke: #f0f0f0;
    --pink-swan: #B7B7B7;
    --nobel: #9c9c9c;
    --blueberry: #4e95ff;
    --alice-blue: #e9f2f8;
    --lavender: #e0edf8;
    --ghost-white: #f5fbff;
    --blue-ribbon: #006ae5;
    --light-cyan: #e5f7f6;

    --azure: #EAF8F8;
    --light-azure: #F4FBFB;
    --dodger-blue: #3091EC;
    --roman: #DB5D53;
    --blush: #FFF4F3;
    --eucalyptus: #299C66;
    --mint-cream: #F4FFF7;
    --persian-green: #00B1A7;
    --glacier: #78BCBC;

    --dark-pink: #832161;
    --lighter-grey: #E9EBED;
    --lighter-color: #68737D;

    --red-100: #FFF0F1;
    --purple-700: #691A4E;
}

;

$text-primary: var(--primary
);
$text-secondary: var(--secondary);
$input-placeholder-color: var(--medium-grey);
$text-tab-inactive: var(--philippine-grey);
$text-tab-active: var(--grey20);

$dropdown-link-active-color: var(--darkliver);
$dropdown-link-active-bg: var(--white);

$h2-font-size: 1.7rem;
$h3-font-size: 1.4rem;
$h4-font-size: 1.2rem;
$h5-font-size: .9rem;
$h6-font-size: .7rem;

$modal-sm: 200px;
$modal-md: 400px;

$enable-rounded: true;

$form-check-input-checked-bg-color: var(--primary);
$form-check-input-focus-border: var(--primary);
$form-check-input-focus-box-shadow: var(--primary);

$body-bg: #F7F7F7;
$component-bg: white;

$red-100: var(--red-100);
$purple-700: var(--purple-700);

$positive-color: var(--positive-color); // Positive indicator color
$negative-color: var(--negative-color); // Negative indicator color