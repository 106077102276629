$footerGiveBack: 255px;

.userPageWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 100px auto 0 auto;
    padding: 0 .5rem;
    position: relative;

    .userPageInner {
        display: flex;
        flex-direction: column;
        height: calc(100vh - #{$footerGiveBack});
        max-height: calc(100vh - #{$footerGiveBack});

        .userTableCheckbox {
            top: -1rem;

            &:before,
            svg {
                width: 22px;
                height: 22px;
            }
        }

        .tableHeaderActions {
            display: flex;
            vertical-align: center;
            align-items: center;
            border: 1px solid var(--grey-200);
            border-radius: 4px 4px 0 0;
            padding: 1rem 1.5rem;

            h5 {
                flex-grow: 1;
                margin-bottom: 0;
            }

            .tableHeaderActionsLeft {
                color: var(--grey-600);

                svg {
                    margin-left: 1rem;
                }
            }

            .tableHeaderActionsRight {
                display: flex;
                align-items: center;

                .tableHeaderActionsSearch {
                    width: 350px;
                    align-self: center;
                    padding: .3rem 0;
                    margin-right: 1rem;

                    svg {
                        font-size: 1.5rem;
                    }
                }

                .tableHeaderActionsButton {
                    margin-left: 1rem;
                }
            }
        }

        .userTableWrapper {
            border: 1px solid var(--grey-200);
            overflow: auto;
        }

        .userTable {
            border-top: none;

            td,
            th {
                border-left: 1px solid var(--grey-100);
            }

            th {
                font-weight: 600;
                padding-top: 1rem;
                padding-bottom: 1rem;
                vertical-align: bottom;
            }

            * {
                white-space: nowrap;
                font-weight: 500;
                font-size: .75rem;
                vertical-align: middle;
            }

            .headerRowAbove {
                height: 20px;
                padding: 0;
                border-bottom: none;
                border-top: none;

                th {
                    padding: 0;
                    background-color: var(--grey-50);
                    text-align: center;
                    height: auto;
                }

                .permissionsHeader {
                    background-color: var(--primary-100);
                    color: var(--grey-500);
                }
            }

            .headerRow {
                background-color: var(--grey-50);
                height: auto;
                vertical-align: bottom;
                align-items: end;

                .headerAuthCell {
                    white-space: normal;
                    min-width: 150px;
                    max-width: 150px;
                }

                .checkboxCell,
                .nameCell,
                .firstNameCell,
                .lastNameCell {
                    background-color: var(--grey-50);
                }
            }

            .bodyRow {
                td {
                    background-color: white;
                }

                &:hover td {
                    background-color: var(--primary-50);
                }
            }

            // the following cell definitions are for ensuring the stick columns maintain correct position
            .checkboxCell {
                position: sticky;
                left: 0;
            }

            .firstNameCell {
                position: sticky;
                left: 49px;
            }

            .lastNameCell {
                position: sticky;
                left: 199px;
                box-shadow: -11px 0 0 -10px var(--grey-200) inset;
            }

            .nameCell {
                border-left: none;
                width: 150px;
                min-width: 150px;
            }

            .propertiesCell {
                min-height: 65px;
                width: 300px;
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
            }

            .authCell {
                text-align: center;

                svg {
                    font-size: 1.2rem;
                }
            }
        }
    }
}