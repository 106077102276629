.bodyRow {
    //height: 56px;
    min-height: 56px;

    .tableCell:first-child {
        border-right: 1px solid var(--grey-200);
    }
}

.fixedSizeList {
    tbody {
        .bodyRow:not(:last-child) {
            border-bottom: 1px solid var(--grey-200);
        }
    }
}

.noBorderBottom {
    border-bottom: none !important;
}

.noBorderLeft {
    border-left: none !important;
}

.noBorderRight {
    border-right: none !important;
}

.noMargin {
    margin: 0 !important;
}

.noPadding {
    padding: 0 !important;
}

.assumptionsWrapper, .assumptnBulkUpdateWrapper {
    :global(.handsontableInput) {
        line-height: 51px;
        text-align: right;
    }
}

.formulaBarWrapper {
    max-height: 26px;
    overflow-y: auto;
    display: flex;
    flex-direction: row;

    :global(.formula-bar-node-columns) {
        height: auto;
        width: auto;
    }
}

.tallerFxBarWrapper {
    max-height: 1.5*3rem;
    overflow: hidden;
}

.labelColumn {
    align-items: center;
    display: flex;
    font-family: var(--times-new-roman-font-stack);
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    padding: 0 4px;
}

.searchWrapper {
    column-gap: 16px;
    padding: 13px 16px;
    .searchWrapperCol {
        align-items: center;
        display: flex;
        flex-grow: 0;
        padding: 0;
    }
    .wrapperTitle {
        align-self: center;
        color: var(--grey-600);
        flex-grow: 1;
        font-size: 0.9rem;
        font-weight: 600;
    }
}

.searchBarForm {
    display: flex;
}

.searchBarField {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.searchBarInput {
    height: 46px;
    // overwrite Zendesk Garden styles:
    width: 332px !important;
}

.dropdownWrapper {
    border-top: 1px solid var(--grey-200);
    gap: 20px;
    margin: 0 !important;
    padding: 9px 12px;
}

.dropdownField {
    width: 400px;
    .dropdownSelect {
        padding: 5px 16px;
        .dummyFlexCol {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }
    }
    svg {
        align-self: center;
        margin: 0;
    }
}

.headerRowCell {
    color: var(--grey-800);
    background: var(--grey-50);
    height: auto !important;
    padding: 16px !important;
    div {
        span {
            font-weight: 600;
        }
    }
}

.headerRowRightAlign {
    div {
        span {
            padding-right: 12px;
            text-align: right;
        }
    }
}

.dropdownLabel {
    color: var(--grey-400);
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
}

.selectedAccountsGrid {
    border: 1px solid var(--grey-200);
    border-radius: 4px;
}

.selectedItem {
    display: flex;
    flex-direction: row;
    font-weight: 500;
}

.driversTable {
    overflow: hidden;
}

.infiniteTable {
    height: calc(100vh - 400px) !important;
    max-height: 800px;
    min-height: 160px;
}

.editFxBarTable {
    tbody {
        .bodyRow:not(:last-child) {
            border-bottom: 1px solid var(--grey-200);
        }
    }
}

.headerRow {
    background-color: #F1F1F1;
    box-shadow: inset 0px -1px 0px var(--grey-200);
    color: var(--grey-600) !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    th:first-child {
        border-right: 1px solid var(--grey-200);
    }
}

.tableCell {
    align-items: center;
    padding: 16px !important;
    overflow-x: auto;
    width: 50%;
}

.checkboxLabel {
    font-size: 0.8rem !important;
    padding-left: 32px !important;
}

.formulaBarCell {
    display: flex;
    width: fit-content;
    column-gap: 8px;
    align-items: center;
    padding-left: 9px;
    color: var(--grey-500);
}

.accountName {
    color: var(--grey-800);
    font-weight: 600;
}

.accountNameText {
    position: relative;
    bottom: -2px;
}

// Progress Indicator
.progressIndicator {
    display: flex;
    flex-direction: column;
    padding: 20px 0 31px;
}

.progressIndicatorRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.iconContainer {
    display: flex;
    justify-content: center;
}

.stepContainer {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    font-weight: 500;
    gap: 8px;
    justify-content: center;
}

.icon {
    border-radius: 12px;
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
}

.activeViewIcon {
    background-color: var(--primary-600);
    color: #FFFFFF;
    display: flex;
}

.inactiveViewIcon {
    background-color: var(--primary-100);
    color: var(--grey-800);
    display: flex;
}

.currentStepTitle {
    display: flex;
}

.horizontalLine {
    align-self: center;
    border-top: 1px solid var(--grey-300);
    display: flex;
    flex-direction: column;
    height: 0;
    margin-top: -23px;
    margin-left: -15px;
    margin-right: -25px;
    width: 313px;
}

// Property drivers
.tableWrapper {
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    padding: 0;
    height: 500px;
    overflow-y: auto;
}

.tableTitleWrapper {
    display: flex;
    justify-content: space-between;
    padding: 21px 90px 21px 4px;
}

.forecastHeaderCol {
    // Absolute value of the width this col occupies
    flex: 0 0 113px !important;
}

.forecastPeriod {
    color: var(--grey-400);
    font-size: 0.7rem;
}

.year {
    font-size: 0.9rem;
    text-transform: capitalize;
}

.tableTitle {
    align-items: center;
    color: var(--grey-600);
    display: flex;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.3rem;
}

.tableHeader {
    background: var(--grey-50);
    box-shadow: inset -1px -1px 0px #E8EAED;
    color: var(--grey-800);
    font-weight: 600;
    padding: 16px;
    th {
        font-size: 0.8rem;
    }
    th:first-child {
        border-right: 1px solid var(--grey-200);
    }
}

.tableHeaderTextCell {
    margin-top: -27px;
}

.tableBodyRow {
    align-items: center;
    .tableBodyCell:first-child {
        border-right: 1px solid var(--grey-200);
    }
}

.tableBodyCell {
    padding: 16px;
}

.accountNameCell {
    background-color: #FFFFFF;
    border-left: none !important;
    padding: 16px !important;
    width: 255px;
}

.averageCell {
    border-right: none !important;
    padding: 16px 12px 16px 2px !important;
    width: 130px;
}

.dataCell {
    padding: 16px 12px 16px 2px !important;
    width: 58px;
}

.wideDataCell {
    width: 99px;
}

.editableFxBarCell {
    box-shadow: inset -1px 0px 0px var(--lighter-grey), inset 0px -2px 0px var(--primary-300);
}

.tableFooter {
    align-items: center;
    background: var(--grey-50);
    box-shadow: 0px 0px 30px rgba(135, 146, 157, 0.25);
    border-radius: 4px;
    margin: 10px 0 0;
    padding: 23px 16px;
}

.bulkUpdateText {
    flex: 0 0 285px;
}

.actionsRow {
    border-top: 1px solid var(--lighter-grey);
    margin-top: 13px;
    padding-top: 32px;
}

.lockButton {
    height: auto !important;
    padding: 0 !important;
    top: -3px;
    position: relative;

    &:disabled {
        background-color: transparent !important;
        color: initial !important;
    }
}

.editableLockButton {
    margin-left: 9px !important;
    margin-top: 3px !important;
    padding: 7px !important;
    border-radius: 18px !important;
}

// Edit assumptions
.assumptionsWrapper {
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    height: 500px;
    // Fixed-width because the content is HoT with fixed-width cells
    // width: 1323px !important;
    :global(.ht_master) {
        :global(.wtHolder) {
            height: 361px !important;
        }
    }
}

.noAssumptionsText {
    align-content: center;
    font-size: 1.3REM;
    font-style: italic;
    font-weight: 300;
    height: calc(100% - 72px);
    justify-content: center;
    margin: 0 !important;
    width: 100%;
}

.assumptnBulkUpdateWrapper {
    background: var(--grey-50) !important;
    margin: 10px 0 0 !important;
    padding: 6px 16px;
}

.updateDriverHoTWrapper {
    overflow-x: auto;
}

.bulkUpdateHoTWrapper {
    overflow-x: auto;
    width: 100%;
}

.bulkUpdateHoT {
    height: 57px !important;
    width: 888px !important;
    :global(.wtHolder) {
        height: 57px !important;
    }
}

.assumptnBulkUpdateText {
    background:var(--grey-50) !important;
    color: var(--grey-600);
    font-size: 0.8rem;
    font-weight: 400;
    width: 272px;
}

.applyAssumptnBlkUpdateBtnWrapper {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    .applyAssumptnBlkUpdateBtn {
        font-size: 0.8rem;
        justify-self: center;
        margin-right: 20px;
    }
}

.summaryRow {
    background-color: var(--grey-50);
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    padding: 10px !important;
    text-align: right !important;
}

.leftAlignCell {
    text-align: left !important;
}

.opDriverUnitBtnWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    button {
        border: 1px solid #D8DCDE;
        color: #474E5D;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 12px;
        height: 32px;
    }
}

.staticCell {
    background-color: #FAFBFB !important;
}

.editableAssumptionsCell {
    box-shadow: inset -1px 0px 0px var(--lighter-grey), inset 0px -2px 0px #5482C6;
}

// Footer
.footer {
    align-items: center;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 30px rgba(135, 146, 157, 0.25);
    display: flex;
    margin: 12px auto 0 !important;
    padding: 16px;
    width: 1321px;
}

.growthFooter {
    width: auto;
}

.facetMenu {
    transition: all .25s ease-in-out;
    gap: .25rem;
    min-width: 100%;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    border-radius: 4px;
    border: 1px solid var(--grey-200);
    box-shadow: 0 2px 3px 0 rgba(black, .15);
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    font-size: 1rem;
    padding: .5rem;

    &.isVisible {
        visibility: visible;
        opacity: 1;
        top: calc(100% + 4px);
        pointer-events: all;
    }

    > button {
        display: block;
        margin-bottom: .5rem;
    }
}
