// TODO:bowman after we get more settled on layout items, refactor this
// to break it up and live alongside corresponding components

// asset living primary brand colors
$asset_blue: #003C71;
$medium_blue: #00A1E4;
$light_blue: #90D7F2;
$asset_green: #A6CE39;
$asset_orange: #F2A900;

// asset living secondary brand colors
$dark_blue: #0F2C50;
$gray_blue: #2E577D;
$medium_gray: #A5BAC9;
$light_gray: #D8DEE1;
$off_white: #F8F6F3;

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 42px;
    width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;

    > iframe {
        height: calc(100vh - 74px);
    }

    .title {
        font-size: 1.6rem;
        border-bottom: 1px solid var(--grey-800);
        padding-bottom: .5rem;
        margin-bottom: 1rem;
        color: var(--grey-800);

        &.titleSmall {
            font-size: .875rem;
            color: var(--grey-600);
            text-transform: uppercase;
            margin-bottom: 1rem;
            border-color: var(--grey-200);
        }
    }

    .text {
        font-weight: 300;
        margin-bottom: 2rem;
    }

    .textPositive {
        color: var(--green-500);
        font-weight: 500;
    }

    .textNegative {
        color: var(--red-500);
        font-weight: 500;
    }

    .header {
        top: 75px;
        position: sticky;
        width: 100%;
        background-color: #ffffff;
        z-index: 4;

        .headerActions {
            display: flex;
            align-items: center;
            padding: .5rem 1rem;

            .downloadButton {
                margin-left: auto;
                margin-right: 1rem;
            }
        }

        .brandColorBand {
            overflow: hidden;
            width: 100%;
            height: 6px;
            white-space: nowrap;
            font-size: 0;

            .colorBar {
                transform: skew(-50deg);
                width: 40%;
                height: 100%;
                display: inline-block;
                margin-left: -1rem;

                &.colorBarBlue {
                    background-color: $medium_blue;
                }

                &.colorBarGreen {
                    width: 30%;
                    background-color: $asset_green;
                }

                &.colorBarOrange {
                    background-color: $asset_orange;
                }
            }
        }
    }

    .executiveSummary,
    .rentSummary,
    .financialSummary {
        display: flex;
        flex-direction: column;
    }

    .banner {
        transition: padding .5s;
        width: 100%;
        padding: 5rem 1rem;
        text-align: center;
        background-size: cover;
        background-position: center;
        position: sticky;
        top: 0;
        color: #ffffff;

        .bannerColorOverlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            opacity: .85;
        }

        .bannerInner {
            height: 100%;
            z-index: 1;
            position: relative;

            h1 {
                transition: font-size .5s;
                font-size: 3rem;
                margin-bottom: 1rem;
            }

            .bannerLogo {
                transition: bottom .5s, width .5s;
                width: 180px;
                position: absolute;
                bottom: -4rem;
                right: 0;
            }
        }

        &.bannerSmall {
            padding: 1rem;
            font-size: 1rem;

            h1 {
                font-size: 1.8rem;
                margin-bottom: 0.25rem;
            }

            .bannerLogo {
                width: 100px;
                bottom: 0;
                top: 0;
                margin: auto;
            }

            span {
                display: none;
            }
        }
    }

    .summaryTabWrapper {
        justify-content: center;
        display: flex;

        .tableOfContents {
            position: sticky;
            display: flex;
            flex-direction: column;
            padding: 2rem;
            top: 230px;
            align-self: flex-start;
            margin-right: 4rem;

            a {
                padding-left: .5rem;
                margin-bottom: .5rem;
                position: relative;

                &:after {
                    transition: all .25s ease-in-out;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 8px;
                    width: 3px;
                    height: 100%;
                    background-color: var(--primary-400);
                    opacity: 0;
                }

                &.activeSection:after {
                    opacity: 1;
                    left: 0;
                }
            }
        }

        .summaryContent {
            // TODO:bowman do we want to limit width like this to reflect the export?
            width: 100vw;
            max-width: 1000px;
            padding-top: 2rem;
        }
    }

    .rentSummary {
        .cards {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            height: fit-content;
            .metricCard {
                display: flex;
                flex-direction: column;
                padding: 1rem;
                background-color: gainsboro;
                margin-top: 0;
                margin-bottom: 0;
                width: 33%;
            }
        }
    }

    .footer {
        padding-top: 7rem;
        padding-bottom: 2rem;
        text-align: center;
        border-bottom: 16px solid var(--grey-200);

        .footerVizLogo {
            display: inline-block;
            width: 80px;
            margin-bottom: -8px;
        }

        .footerPlusIcon {
            transition: margin .5s cubic-bezier(0.19, 1, 0.22, 1);
            display: inline-block;
            margin: 0 .5rem;
            vertical-align: middle;
            color: var(--grey-400);
            position: relative;
            width: 24px;

            svg {
                transition: all .9s cubic-bezier(0.19, 1, 0.22, 1);
                fill: var(--grey-500);
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }

            .footerIconAdd {
                opacity: 1;
            }

            .footerIconHeart {
                opacity: 0;
            }
        }

        .footerPartnerLogo {
            display: inline-block;
        }

        > div {
            display: inline-block;
            cursor: pointer;

            &:hover {
                .footerPlusIcon {
                    margin: 0 .25rem;
                }

                svg {
                    fill: var(--roman);
                    transform: rotateY(180deg);
                }

                .footerIconAdd {
                    opacity: 0;
                }

                .footerIconHeart {
                    opacity: 1;
                }
            }
        }
    }

    // alternate styling for if current user is guest account
    &.isGuestUser {
        top: 0;
        padding-top: 0;

        .header {
            top: 0;
        }
    }

    &.assetLiving {
        .footer .footerPartnerLogo {
            width: 40px;
        }
    }

    &.bella {
        .footer .footerPartnerLogo {
            width: 120px;
        }
    }

    &.ti {
        .banner .bannerInner .bannerLogo {
            width: 40px;
        }

        .footer .footerPartnerLogo {
            width: 40px;
        }
    }

    &.haven {
        .banner .bannerInner .bannerLogo {
            width: 150px;
        }

        .footer .footerPartnerLogo {
            width: 34px;
        }
    }

    &.hff {
        .banner .bannerInner .bannerLogo {
            width: 100px;
        }

        .footer .footerPartnerLogo {
            width: 74px;
            margin-bottom: -8px;
        }
    }
}

.gameModal {
    width: auto !important;
}

.metricBlock {
    margin-bottom: 2rem;

    .metricBlockValues {
        display: flex;

        > div {
            flex-grow: 1;
        }
    }

    // TODO:bowman metricBlockTopLabel/bottomLabel may be redundant,
    // but leaving for now until layout is further tested
    .metricBlockTopLabel {
        font-size: .875rem;
        color: var(--grey-500);
    }

    .metricBlockValue {
        font-size: 1.625rem;
        font-weight: 500;
    }

    .metricBlockBottomLabel {
        font-size: .875rem;
        color: var(--grey-500);
    }

    .metricBlockText {
        margin-top: .5rem;
        color: var(--grey-800);
    }
}
