.column, .row {
    display: flex;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.lightText {
    color: var(--grey-600);
}

.error {
    gap: 10px;
    padding: 16px 0;
}

.hidden {
    visibility: hidden;
}

.actionsRow {
    gap: 16px;
}

.radioField {
    padding: 6px 0;
}

.radioHeader {
    margin-bottom: 8px;
}

.radioLabel {
    font-weight: 500;
}

.title {
    border-bottom: 1px solid var(--grey-200);
    padding: 16px 0;
}

.modal {
    overflow: visible !important;
}

.copyToPeriodTitle {
    padding: 16px 0;
}

.sectionHeader {
    justify-content: space-between;
    padding-top: 16px;
    .sectionTitle {
        font-weight: 600;
    }
    .sectionLabel {
        color: var(--grey-400);
        text-transform: uppercase;
    }
}

.dropdownWrapper {
    margin-top: 10px;
}

.copyDriversBtnWrapper {
    align-self: baseline;
    display: flex;
    justify-content: flex-end;
    padding: 0 !important;
}

.copyDriversBody {
    overflow: visible !important;
    padding-bottom: 32px !important;
    padding-top: 0 !important;
}

.confirmationHeader {
    svg {
        margin-top: -3px;
    }
}

.dropdownOption {
    font-weight: 500;

    .dropdownMetaText {
        display: block;
        margin-left: 1rem;
        margin-top: .25rem;
        font-size: .75rem;
        font-weight: 400;
    }
}

.jobStatus {
    margin-top: 1em;

    .jobStatusText {
        font-weight: bold;
    }

    .jobExplainer {
        font-size: 0.75rem;
        color: var(--grey-400);
    }
}
