.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    height: 44px;
    display: flex;
    align-items: center;
    padding-left: 16px;

    &Text {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.00042765619582496583px;
        text-align: left;
    }
}

.body {
    height: calc(100% - 44px);
    padding: 8px 16px 16px 16px;
    border-top: 1px solid #E9EBED;
}

.content {
    border: 1px solid #E9EBED;
    background-color: #F8F9F9;
    border-radius: 4px;
    padding: 10px 12px 10px 12px;
    height: 100%;
    overflow-y: auto;

    &Text {
        //styleName: Text-sm/default-medium;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.00042765619582496583px;
        text-align: left;
    }
}