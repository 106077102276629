.formulaBar {
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    background: white;
    width: 100%;
    border-radius: 4px;
    padding: 3px;
}

.emptyFxBarText {
    color: var(--grey-500);
    font-family: 'Inconsolata', monospace;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5em;
}

.fxBarLoader {
    display: flex;
    align-self: center;
}

.labelColumn{
    display: flex;
    width: fit-content;
    column-gap: 6px;
    align-items: center;
    padding-left: 9px;
    color: var(--grey-500);
}

// To override the universal selector `*` in app.css
.nodesColumnItem {
    display: flex;
    font-family: inherit;
}

.nodesColumn {
    display: flex;
    column-gap: 5px;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;

    font-family: 'Inconsolata', monospace;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5em;
    color: var(--grey-800);

    width: 800px; // quick and ugly fix for formula bar width pushing content out to the right

    max-height: calc(1.5em * 3);
    overflow-y: auto;
}

.actionsColumn {
    display: flex;
    column-gap: 5px;
    width: fit-content;
    align-items: center;
}

.functionIcon {
    display: flex;
    align-items: center;
    font-family: var(--times-new-roman-font-stack);
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
}

.functionEquals {
    @extend .functionIcon;
    font-weight: 400;
    font-size: 12px;
}
