.wrapper {
    padding: .5rem;
    padding-top: 100px;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 0 auto;

    .noCircularReferences {
        background-color: var(--grey-50);
        font-size: 1rem;
        padding: 2rem 0 2rem 0;
        text-align: center;
        justify-content: center;
    }

    .item {
        display: flex;
        flex-direction: row;
        transition: background-color .25s ease-in-out;

        &:hover {
            background-color: var(--primary-200);
        }

        .accountName {
            align-self: center;
            padding-left: 1rem;
        }

        button {
            margin-left: auto;
            margin-right: 0px;
            padding-right: 1rem;
            align-self: center;
        }
    }
}