.phubV1Shim{
    position: relative;
}

.tabBody{
    position: relative;
    margin: 0 auto 0 auto;
    width: calc(100vw - 80px);
}

.loadingOverlay{
    position: absolute;
    z-index: 100;
    text-align: center;
    background-color: rgba(102, 112, 113, 0.1);
    width: 100%;
    height: calc(100vh - 208px - 26px);
}

.loadingOverlaySpinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}