.table-annual-summary-head {
    height: 60px;
    font-size: 12px;
    position: sticky;
    top: 0;
}

.th-months {
    text-align: center;
    width: 70px;
}

.th-description {
    padding-left: 24px !important;
    width: 50px !important;
}

.as-tbody td {
    height: 48px;
    font-size: 12px;
}

.table-annual-summary {
    background-color: white;
    border-radius: 12px;
    margin: auto;

    border-collapse: separate;
    border-spacing: 0;
}

.table-annual-summary th, .table-annual-summary tr, .table-annual-summary td {
    border: 1px solid var(--bg-grey);
}


.table-annual-summary-head th {
    border-right-color: var(--white);
    padding-top: 12px;
    padding: 12px;
}

.annual-summary-select {
    border: none;
    font-weight: bold;
}

.as-header-total {
    text-align: center;
    border-left: 1.1px solid var(--nobel) !important;
}

.as-header-reference-total {
    text-align: center;
}

.as-table-wrapper {
    overflow-y: auto;
    height: 100%;
}

.as-trow {
    background-color: var(--white);
}