$standardColWidth: 120px;
$notesColWidth: 250px;
$commentsColWidth: 180px;

.wrapper {
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    color: var(--grey-800);
    font-size: .75rem;

    .tableWrapper {
        width: 100%;
        max-height: calc(100vh - 240px);
        overflow: auto;
    }

    .table {
        table-layout: auto;
        width: 100%;
        min-width: 100%;
        border-collapse: separate;
    }

    .headerRow {
        position: sticky;
        top: 0;
        z-index: 2;

        &:after {
            content: "";
            background-color: var(--grey-200);
            width: 100%;
            height: 1px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    td, th {
        width: auto;
        height: 60px;
        padding: .75rem 1rem;
        vertical-align: middle;
        background-color: #ffffff;
        border-bottom: 1px solid var(--grey-200);

        > div {
            min-width: 100px;
        }

        &:first-child {
            width: 300px;
            min-width: 300px;
        }

        &:not(:last-child) {
            border-right: 1px solid var(--grey-200);
        }

        &:not(:first-child), button {
            text-align: right;
        }

        &.isPositive {
            color: var(--positive-color);
        }

        &.isNegative {
            color: var(--negative-color);
        }

        &.greyCell {
            background-color: var(--grey-200);
        }
    }

    &.revTable {
        td, th {
            height: 50px;
            padding: .25rem .5rem;

            > div {
                width: 80px;
                min-width: 80px;
            }
        }
    }

    th, button {
        font-weight: 600;
    }

    .topHeader {
        padding: 1.5rem 1rem;
        border-bottom: 1px solid var(--grey-200);
        display: flex;
        align-items: center;
        height: 68px;

        h4 {
            font-size: 1rem;
            color: var(--grey-600);
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 0;
        }

        h5 {
            color: var(--grey-500);
            font-weight: 400;
            margin-top: .25rem;
            margin-bottom: 0;
        }

        .headerActions {
            display: flex;
            align-items: center;
            margin-left: auto;
        }
    }

    .descriptionCell {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .fixedCol {
        position: sticky;
        z-index: 1;
        border-right: 0 !important;

        &.firstCol {
            left: 0;

            &:after {
                content: "";
                background-color: var(--grey-200);
                width: 3px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        &.endCols {
            &:after {
                content: "";
                background-color: var(--grey-200);
                width: 1px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        &.firstEndCol {
            &:after {
                content: "";
                background-color: var(--grey-200);
                width: 3px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        &.budgetTotal {
            right: $standardColWidth * 3;
        }

        &.reforecastTotal {
            right: $standardColWidth * 2;
        }

        &.varianceAmt {
            right: $standardColWidth;
        }

        &.variancePct {
            right: 0;
        }

        &.lastCol {
            right: 0;

            &:after {
                content: "";
                background-color: var(--grey-200);
                width: 1px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        &.notesColumn, &.commentsColumn {
            text-align: left;
            right: 0;

            &:after {
                content: "";
                background-color: var(--grey-200);
                width: 3px;
                height: 100%;
                position: absolute;
                left: -1px;
                top: 0;
            }

            > div {
                width: $notesColWidth;
            }
        }

        &.commentsColumn {
            > div {
                width: $commentsColWidth;
            }
        }

        .noteAndCommentCells {
            display: flex;
            align-items: center;

            .noteAndCommentCellsText {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .noteAndCommentCellsIcon {
                margin-left: auto;
            }
        }
    }

    // &.notesOpen, &.commentsOpen {
    //     .fixedCol.budgetTotal {
    //         right: calc(#{$notesAndCommentsColWidth} + (#{$standardColWidth} * 3));
    //     }

    //     .fixedCol.reforecastTotal {
    //         right: calc(#{$notesAndCommentsColWidth} + (#{$standardColWidth} * 2));
    //     }

    //     .fixedCol.varianceAmt {
    //         right: calc(#{$notesAndCommentsColWidth} + #{$standardColWidth});
    //     }

    //     .fixedCol.variancePct {
    //         right: $notesAndCommentsColWidth;
    //     }
    // }

    &.notesOpen.commentsOpen {
        .notesColumn {
            right: $commentsColWidth + 32px;
        }

        .commentsColumn {
            &:after {
                content: "";
                background-color: var(--grey-200);
                width: 1px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        // .fixedCol.budgetTotal {
        //     right: calc((#{$notesAndCommentsColWidth} * 2) + (#{$standardColWidth} * 3));
        // }

        // .fixedCol.reforecastTotal {
        //     right: calc((#{$notesAndCommentsColWidth} * 2) + (#{$standardColWidth} * 2));
        // }

        // .fixedCol.varianceAmt {
        //     right: calc((#{$notesAndCommentsColWidth} * 2) + #{$standardColWidth});
        // }

        // .fixedCol.variancePct {
        //     right: calc(#{$notesAndCommentsColWidth} * 2);
        // }
    }

    .expandButton {
        vertical-align: sub;
        margin-right: .25rem;
    }

    .rowHidden {
        display: none;
    }

    .settingsMenu {
        display: flex;
        flex-direction: column;
        min-width: 300px;

        .visibilityOptions {
            padding: .75rem;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid var(--grey-200);

            .settingsMenuHeader {
                font-size: .75rem;
                font-weight: 500;
                margin-bottom: 1rem;
                color: var(--grey-500);
                text-transform: uppercase;
            }

            label {
                margin-left: .75rem;
                margin-bottom: .5rem;
            }
        }

        .otherOptions {
            padding: .5rem;
        }
    }
}

.noteModalBody {
    padding: 1rem 2.5rem;
}

.modelingMethodCell {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px !important;
}

.methodBadge {
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.7rem;
    font-weight: 500;
    background-color: var(--primary-25);
    color: var(--primary-300);
    border: 1px solid var(--primary-300);
    white-space: nowrap;
    &.hasOverride {
        background-color: var(--magenta-25);
        color: var(--magenta-300);
        border: 1px solid var(--magenta-300);
    }
}

.functionIcon {
    align-items: center;
    font-family: var(--times-new-roman-font-stack);
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}
