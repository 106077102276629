.toggle-container,
.toggle {
    border-radius: 7px;
}

.toggle-container {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 2px;
    padding-left: 2px;
    height: 32px;
    margin-bottom: 2px;
    width: 210px;
}

.toggle {
    white-space: nowrap;
    font-size: 12px;
    text-align: center;
}