@keyframes pulse {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.shimmer {
    animation: pulse 1.3s ease-in infinite;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    &General{
        max-width: 1600px;
        padding: 50px;
    }

    &Analyst{
        max-width: calc(100vw - 80px);
    }

    &Report{
        padding: 0;
    }

    img {
        display: block;
        align-self: center;
    }
}
