.wrapper {
    padding: .5rem;
    padding-top: 100px;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 0 auto;


    .propertySearch {
        width: 200px;
        float: right;
    }

    .propertyTableWrapper {
        max-height: 500px;
        overflow-y: auto;
    }

    .propertyTable {
        width: 100%;
        table-layout: fixed;
        font-size: .75rem;
    }

    .cell {
        padding: 1rem;
        border-right: 1px solid var(--grey-100);
        border-bottom: 1px solid var(--grey-100);

        &.nameCell {
            width: 350px;
        }

        &.periodCell {
            text-align: center;
            width: 130px;

            &.withButton {
                padding: 0.5rem;
            }
        }
    }

    .headerCell {
        .cell {
            position: sticky;
            top: 0;
            font-weight: 500;
            background-color: var(--grey-50);
            padding-top: 2rem;
        }
    }

    .updaterControlsCard {
        background-color: var(--grey-50);
        margin-bottom: 1.5rem;
        padding: 0 1rem 1rem 1rem;
    }

    .budgetSeasonsCard {
        margin-bottom: 1.5rem;
        // padding: 0 0rem 1rem 0rem;
    }

    .updaterControlsSteps {
        margin: 0;
        padding-left: 1rem;

        > li {
            line-height: 1.5rem;
            margin-bottom: 1rem;
        }
    }

    .updaterControlsTitle {
        height: 60px;
        padding: 0;
        margin-bottom: 1rem;
    }

    .updaterControlsButtons {
        float: right;

        .button {
            margin-left: 1rem;
        }
    }
}