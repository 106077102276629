// Unit Level Modeling: Month to Month data point
.highcharts-color-10{
    fill: #FEF4F2;
}

// Unit Level Modeling: Renewal data point
.highcharts-color-11{
    fill: #E5F0F0;
}

// Unit Level Modeling: Renovation data point
.highcharts-color-12{
    fill: #FBEEf4;
}

// Unit Level Modeling: Short Term Rental data point
.highcharts-color-13{
    fill: #FDF6E3;
}

// Unit Level Modeling: Term Lease data point
.highcharts-color-14{
    fill: #E4F0FF;
}

// Unit Level Modeling: Imported Event data point
.highcharts-color-15{
    fill: #EFF0F7;
}


