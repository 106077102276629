.menuTitle {
    max-width: 100%;
    display: flex;

    .menuTitleText {
        text-transform: uppercase;
        font-weight: 500;
        font-size: .875rem;
        color: var(--grey-600);
        margin-bottom: 0;
        margin-right: 6px;
    }

    &.menuTitleSmall {
        .menuTitleText {
            font-size: .75rem;
        }
    }

    &.menuTitlePlain {
        .menuTitleText {
            text-transform: none;
            font-weight: 400;
        }
    }

    &.menuTitleTextAutocomplete {

        // explicit pixel amounts are helpful to make sure the autocomplete menu fits nicely
        // at different screen sizes
        .menuTitleText {
            min-height: 0;
            padding: 0;
            width: 300px;
            max-width: 100%;
            border: none;
            border-radius: 0;
            margin-top: .1rem;
            box-shadow: none;
        }

        .menuTitleOptionList {
            width: 500px;
            min-width: 500px;
            max-height: 160px;

            .menuTitleOptionBold {
                font-weight: 500;
            }
        }
    }

    &.alignCenter {
        text-align: center;
    }

    &.alignRight {
        text-align: right;
    }

    .menuTitleOption {
        padding-left: 16px;
        padding-right: 16px;
    }

    .menuTitleOptionIcon {
        margin-right: 8px;
    }

    .menuTitleOptionIconHidden {
        visibility: hidden;
    }

    &.menuTitleHasOptions:not(.menuTitleNoUnderline) {
        .menuTitleText {
            text-decoration: underline;
        }
    }
}