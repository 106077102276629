.wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.dropdown {
    width: 15rem;
}

.input {
    width: 9rem;
}