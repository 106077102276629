.note-icon-blank {
    cursor: pointer;
    font-size: 15px;
    padding: 3px;
    border-radius: 2px;
}

.note-icon-filled {
    cursor: pointer;
    font-size: 15px;
}

.month-values {
    width: 70px !important;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
}

.as-total {
    width: 90px !important;
    padding-right: 12px;
    padding-left: 12px;
    text-align: center;
    border-left: 1.1px solid var(--nobel) !important;
}

.actuals {
    width: 90px !important;
    text-align: center;
}

.variance {
    width: 54px !important;
    text-align: center;
}