.sheetContainer {
    position: relative;
    width: 100%;
    max-height: calc(100vh - 208px - 26px);
    background-color: white;
    border: 1px solid var(--grey-300);
}

.loadingActivityContainer {
    position: absolute;
    top: 26px;
    left: 235px;
}
