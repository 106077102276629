.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.pageHeader {
    color: var(--grey-800);
    gap: 6px;
    margin: 100px auto 0;
    padding-left: 1px;
    max-width: 1440px;
    .pageTitle {
        font-size: 2rem;
        font-weight: 600;
    }
    .pageDescription {
        font-size: 0.9rem;
        font-weight: 500;
        padding-left: 1px;
    }
}

.tableWrapper {
    border: 1px solid var(--grey-200) !important;
    border-radius: 4px;
    margin: 32px auto 40px;
    max-height: calc(100vh - 248px);
    max-width: 1440px;
    overflow-y: auto;
}

.table {
    border-radius: 4px;
    .header {
        background-color: var(--grey-50);
        border-bottom: 1px solid var(--grey-100);
        height: 60px;
        pointer-events: none;
        .headerCell {
            color: var(--grey-600);
            font-size: 0.9rem;
            font-weight: 600;
            padding-top: 24px;
        }
    }
    .bodyRow {
        border-bottom: 1px solid var(--grey-100);
        &:last-child {
            border-bottom: none;
        }
        .bodyCell {
            color: var(--grey-800);
            font-size: 0.9rem;
            font-weight: 500;
            padding: 12px;
        }
    }
    .downloadButton {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 24px;
        justify-content: center;
        padding: 0;
        transition: background-color 0.3s ease-in-out;
        width: 24px;
        &:hover {
            background-color: rgba(13, 102, 109, 0.3);
        }
    }
}
