.insightsList {
    font-size: 1em;
    font-weight: 400;

    & li{
        padding-bottom: 0.5em;
    }
}

.changeValue {
    font-weight: 500;
}

.hilightValue {
    font-weight: 600;
}

.contribution {
    padding-bottom: 3px;
}

.insightsTab {
    padding: 0 6px;

    & section:not(:last-child) {
        border-bottom: 1px solid var(--grey-100);
        margin-bottom: 6px;
    }
}

.insightsHeading {
    display: block;
    padding: 1em 1.1em 0 1em;
    font-size: .7rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--grey-500);
}

.insightsBody {
    font-size: 0.8rem;
    padding: 1rem 1.1rem 1.2rem 1rem;

    ul {
        padding-left: 1rem;
        padding-bottom: .75rem;
        margin-bottom: 0;

        li:not(:last-child) {
            margin-bottom: .25rem;
        }
    }
}
