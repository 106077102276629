.propertyTableWrapper {
    max-height: 500px;
    overflow-y: auto;
}

.propertySearch {
    width: 200px;
    float: right;
}


.propertyTable {
    width: 100%;
    table-layout: fixed;
    font-size: .75rem;
}

.headerCell {
    .cell {
        position: sticky;
        top: 0;
        font-weight: 500;
        background-color: var(--grey-50);
        z-index: 50;
    }
}

.cell {
    padding: 1rem;
    border-right: 1px solid var(--grey-100);
    border-bottom: 1px solid var(--grey-100);

    &.nameCell {
        width: 350px;
        font-size: 20px;
    }

    &.periodCell {
        text-align: center;
        width: 130px;
    }
}

.verticalBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    .statusFilter {
        width: 13rem;
    }

    span {
        font-size: 20px;
    }
}
