@import "../../../../../../colors.scss";

.totalColumn {
    background-color: var(--grey-200);
    border-right: 3px solid var(--grey-300) !important;
}

.columnHeaderBase{
    height: 72px !important;
    padding: 0 0 10px 14px !important;
    vertical-align: bottom !important;

    font-weight: 500 !important;
}

.reforecastValueColumnHeader {
    background-image: url("../../../../../../assets/images/spreadsheet/reforecast_hdr_background.png");
    background-repeat: repeat-x;
    background-position: top;
}

.budgetValueColumnHeader {
    background-image: url("../../../../../../assets/images/spreadsheet/budget_hdr_background.png");
    background-repeat: repeat-x;
    background-position: top;
}

/*
 * This is a stupid nasty hack because we decided to change
 * HandsOnTable's global styling. Why we did that I will never
 * know.
 */
.sortableHeader span:global(.colHeader.columnSorting) {
    width: auto;
}

.totalsColumnHeader {
    background-color: var(--grey-200);
    border-right: 3px solid var(--grey-300) !important;
}

.columnHeaderBody {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    height: 100% !important;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;

    .entityHeader {
        padding: 0 4px 0 0;
    }

    .budgetType{
        padding: 0 8px 0 0;
    }

    .year{
        padding: 0 8px 0 0;
    }

    .month{
        padding: 5px 8px 6px 0;
        font-weight: 600;
    }
}