.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.typography {
    //styleName: Text-sm/default-medium;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
    text-align: left;
}

.labelContainer {
    display: flex;
    flex-direction: column;
}

.labelMain {
    @extend .typography;
    color: #333842;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 190px; // TODO: figure out better solution as this does not dinamically adjust for for holding container width
}

.labelSub {
    @extend .labelMain;
    size: 10px;
    color: #66708580;
}

.value {
    @extend .typography;
    margin-left: auto;
}

.secondaryColorOverride {
    color: #667085;
}