.accountNoteTextArea {
    width: 100%;
    height: 100%;
    border: 1px solid white;
    background-color: var(--off-white);
    border-radius: 8px;
}

.accountAddNoteButton {
    color: #FFFFFF !important;
    background-color: #5D6CB1 !important;
    &:hover {
        background-color: #4A568E !important;
    }
    &:active {
        background-color: #38416A !important;
    }
    &:focus {
        background-color: #5D6CB1 !important;
    }
}

.accountViewNoteButton {
    color: #5D6CB1 !important;
    border: 1px solid #5D6CB1 !important;
    background-color: transparent !important;
    &:hover {
        background-color: #FFFFFF !important;
    }
    &:active {
        background-color: #CED3E8 !important;
    }
    &:focus {
        background-color: #EFF0F7 !important;
    }
}

