.pill {
    //styleName: Text-xsm/default-semi-bold;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
    text-align: center;

    height: 18.687726974487305px;
    border-radius: 10px;
    padding: 2px 8px 2px 8px;
}

.opDriver {
    @extend .pill;
    background-color: #F97068;
    color: white;
}

.pctOfAccount {
    @extend .pill;
    background-color: #FED766;
    color: #333842
}

.lineItems {
    @extend .pill;
    background-color: #C6D4FF;
    color: #474E5D;
}

.manualEntry {
    @extend .pill;
    background-color: #0343A1;
    color: white;
}

.override {
    @extend .pill;
    background-color: #C98BB9;
    color: white;
}

.payroll {
    @extend .pill;
    background-color: #DBE8E9;
    color: #525A6A;
}

.renovationCosts {
    @extend .pill;
    background-color: #F4DCE9;
    color: #525A6A
}

.mixedMethods {
    @extend .pill;
    background: linear-gradient(0deg,
                    rgba(255, 255, 255, 0.85),
                    rgba(255, 255, 255, 0.85)
                ),
                linear-gradient(0deg, #667085, #667085);
    color: #525A6A;
}

.revenue {
    @extend .pill;
    background-color: #004934;
    color: white;
}