@import "../../colors";

.dashboard{
    position: relative;
    padding-top: 75px;

    .mainHeader{
        height: 84px;

        .viewTitle {
            display: inline-block;
            padding: 24px 10px 0px 40px;
            font-size: 26px;
            font-weight: 500;
            color: var(--darkliver);
        }

        .betaTag{
            display: inline-block;
            width: 50px;
            height: 28px;
            text-indent: -9999em;
            padding-bottom: 3px;
            background-image: url('../../assets/images/planning_hub_beta_tag.png');
            background-repeat: no-repeat;
        }

        .btnAddStaff{
            float: right;
            margin-top: 26px;
            margin-right: 46px;
            max-width: 111px;
            position: relative;
            background-color: #0a8585;
        }
    }

    .loadbarContainer{
        position: relative;
        display: block;
        min-height: 6px;
    }

    &Hidden{
        display: none;
    }

    //DEPRECATE ONCE CONFIRMED OK - This is the react-bootstrap loader style which has been replaced in the planning hub w/ ZDG
    :global .progress {
        border-radius: 0;
        height: 0.5rem;

        :global .progress-bar {
            background: linear-gradient(90deg, map-get($theme-colors, "primary") 0%, map-get($theme-colors, "primary") 90%, transparent 100%);
        }
    }
}
