.container {
    height: 100%;
    overflow-y: scroll;
    ul {
        padding-left: 0;
        list-style-type: none;
        li {
            summary {
                border-bottom: 1px solid #E9EBED;
                padding-left: calc(16px + 24px * var(--level));
                padding-right: 16px;
                height: 40px;
                line-height: 40px;

                > div {
                    display: inline-flex;
                    // prevent passing down line-height from above
                    // let the downstream elements to have clear
                    // environment
                    // line-height: initial;
                    vertical-align: middle;
                    width: 100%;
                }
            }

            // marker styling
            details > summary {
                list-style-image: url("../assets/overflow-icon-plus.svg");
                > div {
                    width: calc(100% - 20px); // 20px is the width of the 
                    vertical-align: middle;
                }
            }

            details > summary::-webkit-details-marker {
                background: url("../assets/overflow-icon-plus.svg");
                color: transparent;
                width: 12px;
                height: 12px;
            }

            details[open] > summary {
                list-style-image: url("../assets/overflow-icon-dash.svg");
            }

            details[open] > summary::-webkit-details-marker {
                background: url("../assets/overflow-icon-dash.svg");
                color: transparent;
            }
        }

        // li:last-child {
        //     border-bottom: none;
        // }
    }
}

.tree {
    padding: 0 !important;
    overflow: auto !important;
}

.row {
    height: 40px;
    padding-left: calc(12px * var(--level));
    border-bottom: 1px solid #E9EBED;
}

.treeNode {
    cursor: pointer;

    summary {
        @extend .row;
    }

    // marker styling
    summary {
        font-weight: bold;
        list-style-image: url("../assets/overflow-icon-plus.svg");
    }

    summary::-webkit-details-marker {
        background: url("../assets/overflow-icon-plus.svg");
        color: transparent;
        width: 12px;
        height: 12px;
    }

    details[open] > summary {
        list-style-image: url("../assets/overflow-icon-dash.svg");
    }

    details[open] > summary::-webkit-details-marker {
        background: url("../assets/overflow-icon-dash.svg");
        color: transparent;
    }

    ul {
        padding: 0;
    }
}

.treeLeaf {
    @extend .row;
    cursor: default;
}