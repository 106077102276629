.varianceNoteContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.varianceNoteHeader {
    padding-left: 16px;
    height: 36px;
    min-height: 56px;

    display: flex;
    align-items: center;

    border-bottom: 1px solid var(--grey-200);
}

.varianceNoteLabel {
    //styleName: Text-md/default-semi-bold;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.15399999916553497px;
    text-align: left;
}

.varianceNoteFooter {
    height: 56px;
    min-height: 56px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
}

.saveButton {
    justify-self: flex-end;
}

.varianceNoteTextContainer {
    height: calc(100% - 36px - 56px);
    padding-left: 15px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--grey-200);
}

.varianceNoteText {
    height: 100%;

    //styleName: Text-sm/default-medium;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
    text-align: left;
}