.errorAlertContainer{
    position: absolute;
    display: block;
    z-index: 5;
    width: calc(40vw);
    max-width: 500px;
    height: 100px;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
}

.datePickerContainer{
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    align-items: center;
}
