
.tableAndFooterContainer {
    position: relative;
    height: 100%;
}

// TABLE CONTAINER
.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100% - 80px);
    max-height: calc(100% - 80px);
}

.scrollBottomShadow {
    box-shadow: 0px -15px 10px -9px rgba(0,0,0,1) inset;
}

.table {
    border-bottom: 2px solid #6670854D;

    th:first-of-type {
        padding-left: 16px;
    }

    th:last-of-type {
        padding-right: 16px;
    }

    td:first-of-type {
        padding-left: 16px;
    }

    td:last-of-type {
        padding-right: 16px;
    }
}

// HEADER

.headerRow {
    height: 40px !important;
}

.headerCell {
    //styleName: Text-sm/default-semi-bold;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
    text-align: left;
    vertical-align: middle;
}

.toggleExpandDetailsHeaderCell{
    @extend .headerCell;
    padding: 0 !important;
    width: 50px;
}

.amountHeaderCell {
    @extend .headerCell;
    width: 91px;
    text-align: right !important;
}

.descriptionHeaderCell {
    @extend .headerCell;
    padding-left: 16px;
}

.dateHeaderCell {
    @extend .headerCell;
    width: 76px;
}


// TABLE
.rowFont{
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
}

.tableCell {
    //styleName: Text-sm/default-medium;
    position: relative;
    text-align: left;
    vertical-align: middle;
    @extend .rowFont;
}

.expandDetailsTableCell {
    padding: 0 0 0 4px !important;
    width: 20px !important;
    vertical-align: middle;
}

.descriptionTableCell {
    @extend .tableCell;
    padding-left: 4px !important;
    height: 100%;
}

.descriptionContainer{
    display: flex;
    height: 100%;
    column-gap: 2px;
}

.description{
    @extend .rowFont;
    display: table-cell;
    height: 32px;
    padding-top: 8px; // FIXME: Incorrect solution; Moving on for time's sake.
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.amountTableCell {
    @extend .tableCell;
    text-align: right;
    vertical-align: middle;
    padding-right: 16px;
}

.noDataAvailableRow {
    background-color: #FAFBFB;

    //styleName: Text-sm/default-medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
    text-align: left;

    color: #66708580;

    vertical-align: middle;
}

.tableFooterContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    border-top: 2px solid #6670854D;
}

.totalRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 40px;

    //styleName: Text-sm/default-semi-bold;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.00042765619582496583px;
    text-align: left;
}

.paginationRow {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    border-top: 1px solid #E9EBED;

    &ItemStats {
        flex: 1 33%;
        min-width: fit-content;
        color: #66708580;

        font-size: 10px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.00042765619582496583px;
        text-align: left;

    }

    &PaginationContainer {
        flex: 2 33%;
        display: flex;
        justify-content: center;

        // override default bootstrap css behavior described here:
        // https://getbootstrap.com/docs/4.0/content/reboot/#html5-hidden-attribute
        // reason: zd pagination uses "hidden" html attribute to hide
        // chevron when navigated to the boundary page (first or last)
        // for the first page (left chevron) the bootstrap treatment of the "hidden"
        // attribute (as display: none) makes the chevron container to be removed
        // from DOM and effectively shifts the whole pagination element to the left
        // as a result this is perceived as the whole pagination element "jumping"
        // to the left.
        // to see this "jumping" behavior just disable (comment out) the [hidden]
        // definition below and try navigating between pages 1 and 2 in pagination
        [hidden] {
            display: flex !important;
        }
    }

    &Column {
        flex: 1 33%;
    }
}
