.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 1150px;
    height: 700px;
}

.left-side {
    background-color: var(--white);
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.left-side-container {
    padding: 80px;
    height: 700px;
}

.orange-box {
    width: 410px;
    height: 370px;
    background-color: var(--soft-orange);

    position: fixed;
    z-index: -1;
    margin-left: 200px;
    margin-top: -150px;
    border-radius: 7px;
}

.light-blue-box {
    position: absolute;
}

.right-side {
    background-color: var(--mabel);

    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.optimize-text {
    color: var(--white);
    font-size: 20px;
    padding-top: 40px;
    text-align: center;
}

.right-side-login {
    background-color: var(--primary);
}

.right-side-image-login {
    display: flex;
    // padding-top: 150px;
    text-align: center;
    justify-content: center;
}

.login-image {
    display: flex;
}

.start-box-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 70px;
    padding-top: 150px;
}

.terms-and-privacy {
    color: var(--gainsboro);
    font-size: 10px;
}

.welcome-back-text {
    color: var(--darkliver);
    font-size: 32px;
}

.form-title-vizibly {
    color: var(--secondary);
    font-size: 48px;
    font-weight: bold;
}

.login-text {
    color: var(--darkliver);
    font-size: 18px;
    padding-bottom: 12px;
}


.login-form-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.login-forgot-password {
    text-decoration: none;
    color: var(--silver);
    font-size: 14px;
    outline: 0;
}

.login-forgot-password:hover {
    color: var(--darkliver);
}

.login-box, .signup-box {
    height: 70px;
    min-width: 372px;
    border: 1px solid var(--gainsboro);
    border-radius: 7px;
    align-self: center;
}

.login-password {
    position: relative;
    padding-left: 5px;
    margin-left: 15px;
    top: -13px;
    background-color: var(--white);
    color: var(--nobel);
    width: 85px;
}

.login-new-password-width {
    width: 121px;
}

.signup-username {
    position: relative;
    padding-left: 5px;
    margin-left: 15px;
    top: -13px;
    background-color: var(--white);
    color: var(--nobel);
    width: 85px;
}

.signup-password {
    position: relative;
    padding-left: 5px;
    margin-left: 15px;
    top: -13px;
    background-color: var(--white);
    color: var(--nobel);

}

.signup-width {
    width: 85px;
}

.confirm-password {
    width: 105px;
    display: inline;
}

.login-email, .signup-email {
    position: relative;
    padding-left: 5px;
    margin-left: 15px;
    top: -13px;
    background-color: var(--white);
    color: var(--nobel);
    width: 50px;
}

.login-input, .signup-input {
    border: none;
    outline: 0;
    width: 90%;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}

input.login-input {
    caret-color: var(--darkliver);
}

.focused-border {
    border: 1px solid var(--primary);
}

.focused-text {
    color: var(--primary);
}

.error-border {
    border: 1px solid red;
}

.error-text {
    color: red;
}

.password-error {
    font-size: 12px;
    font-weight: 600;
}

.default-border {
    border: 1px solid var(--gainsboro);
}

.default-text {
    color: var(--nobel);
}

.error-message {
    color: red;
    font-weight: 500;
    font-size: 12px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.login-input-wrapper, .signup-input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
}

.eye-icon-wrapper {
    padding-right: 20px;
    font-size: 20px;
}

.reset-pw {
    padding-top: 100px;
}

// REQUEST CODE
.forgot-password,
.reset-password {
    font-size: 32px;
    color: var(--darkliver);
    // padding-top: 60px;
}

.reset-password-space-top {
    margin-top: 30px;
}

.forgot-password-text {
    font-size: 18px;
    color: var(--darkliver);
}

.request-code-buttons, .button-padding {
    padding-top: 20px;
}

.password-reset-true {
    font-size: 12px;
}

.forgot-password-buttons,
.resend-code-buttons {
    display: flex;
    justify-content: space-between;
}

.back-to-login {
    font-size: 14px;
    color: var(--nobel);
    cursor: pointer;
    padding-top: 5px;
}

.resend-code-paragraph {
    padding-top: 15px;
    line-height: 14px;
}

.resend-code {
    font-size: 12px;
    color: var(--primary);
    cursor: pointer;
}

.send-code-button,
.reset-code-button {
    cursor: pointer;
}

.email-icon {
    padding-right: 10px;
}

.reset-code-text {
    font-size: 12px;
    color: var(--nobel);
    padding-top: 15px;
}

// FORGOT PASSWORD
.password-requirements-lineone {
    padding-bottom: 10px;
}

.password-requirements {
    font-size: 12px;
    color: var(--darkliver);
    padding-bottom: 2px;
    padding-top: 2px;
}

.dash-icon {
    padding-right: 10px;
}

.reset-password-wrapper  {
    padding-bottom: 8px;
    padding-top: 30px;
}

