.curveTableCell {
    padding: 2px !important;
    box-shadow: none !important;
}

.curveTableHeader {
    text-align: center !important;
}

.curveInput {
    padding: 0.2em !important;
    width: 100%;
    height: 100%;
    text-align: right;
}

.curveSmallHeader {
    margin: 2rem 0 0.5rem 0;
    color: var(--grey-500);
}

.curveModalBody {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.curveCountTableHeader {
    text-align: center !important;
}

.curveCountTableUnitType {
    text-overflow: ellipsis;
}

.curveCountTableNumber {
    text-align: right !important;
}

@media (min-width: 1024px) {
    .curveModal {
        min-width: 1024px;
    }
}
