.topNavWrapper {
    gap: .25rem;
}

.topNav{
    transition: background .25s ease-in-out;
    display: flex;
    align-items: center;
    align-self: center;
    padding: 0 1rem;
    font-size: .875rem;
    font-weight: 500;
    border-radius: 4px;
    height: 40px;
    line-height: 38px;
    gap: .5rem;
    color: var(--primary-400);
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: var(--primary-400);
        background: var(--primary-50);
    }

    &On {
        background: var(--primary-100) !important;
    }
}

.readOnlyHeader {
    display: flex;
    width: calc(100vw);
    padding: 8px;
    justify-content: center;
    color: #884333;
    background-color: #FFDDD6;
    font-face: Inter;
    font-size: 14px;
    font-weight: 500;

    &Fixed{
        display: block;
        position: fixed;
        top: 74px;
        text-align: center;
    }
}
