.shimmerContainer {
    margin-top: 35px;
    border: 1px solid #D1D4DA;
    border-radius: 6px;
}

.tableContainer {
    margin-top: 35px;
    border: 1px solid #D1D4DA;
    border-radius: 6px;

    .caption {
        color: #474E5D;
        font-weight: 600;
        font-size: 14px;
        height: 45px;
        padding: 12px;
    }

    .headerRow {
        border-top: 2px solid #D1D4DA;
        background-color: #F1F1F1;
        color: #333842;
        font-weight: 600;
        font-size: 12px;
    }

    .tableRowsContainer {
        max-height: 200px;
        overflow-y: auto;
    }

    .glDetailsText {
        font-weight: 500;
        font-size: 12px;
        font-family: Inter;
        color: #0D666D;
    }

    .glNameContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reforecastBudgetValue {
        font-family: Inter;
        font-weight: 600;
        font-size: 12px;
        color: #333842;
    }

    .varValue {
        font-family: Inter;
        font-weight: 500;
        font-size: 12px;
        color: #333842;
    }

    .varPercentageValContainer {
        border: none;
        border-radius: 7px;
        min-width: 32px;
        max-width: 61px;
        text-align: center;
        padding: 5px;
        font-weight: 500;
        font-size: 12px;
    }

    .green {
        background-color: #99CABB;
        color: #006245;
    }

    .red {
        background-color: #F5D5D8;
        color: #CC3340;
    }

    .inlineNoteContainer {
        font-family: Inter;
        color: #333842;
        position: relative;
    }

    .inlineNoteViewMode {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        cursor: pointer;
    }

    .inlineNoteAddNoteButton {
        color: #0D666D !important;
        background-color: transparent !important;
        &:hover {
            color: #0A5257 !important;
            background-color: #E7F0F0 !important;
        }
    }

    .inlineNoteEditMode {
        overflow: auto;
        position: absolute;
        z-index: 10;
        min-width: 100px;
        width: 100%;
        background-color: white;
    }
}
