.modalPane {
    display: block;
    min-width: 1230px;
    background-color: #F2F7F8 !important;

    &LeftCol {
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding-bottom: 1rem;
    }

    &RightCol {
        height: 100%;
        padding-bottom: 1rem;
    }
}

.modalInnerCell {
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--grey-200);
}

.widgetsPane {
    height: 65%;
    flex-grow: 0;
    flex-shrink: 0;
}

.varianceNotePane {
    height: 35%;
    flex-grow: 0;
    flex-shrink: 1;
}

.modalContent {
    display: flex;
    flex-direction: column;
}

.modalHeader {
    height: 52px;

    &Content {
        display: flex;
        //styleName: Text-lg/default-semi-bold;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.44999998807907104px;
        text-align: left;

        align-items: center;
    }

    &ChevronButton {
        padding-left: 2px;
        padding-right: 2px;
        height: 20px;
        color: #667085;
    }
}

.modalCloseButton {
    right: 10px;
}

.modalBody {
    height: 560px;
}

.rightColHeader {
    display: flex;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid var(--grey-200);

    &Label {
        padding: 11px 0 0 14px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
}

.rightColBody {
    height: calc(100% - 40px);
    display: grid;
    grid-template-columns: calc(100% / 8 * 5) calc(100% / 8 * 3);
    grid-template-rows: 100%;
}

.budgetCol {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: 1px solid var(--grey-200);
}

.budgetAssumptionsPane {
    height: 65%;
    flex-grow: 1;
    flex-shrink: 1;
}

.budgetNotePane {
    height: 35%;
    width: 100%;
    margin-bottom: 0;
    margin-top: auto;
    border-top: 1px solid var(--grey-200);
}

.actualVsBudgetNotePane {
    height: 188px;
    width: 100%;
    margin-bottom: 0;
    margin-top: auto;
    border-top: 1px solid var(--grey-200);
}

.modalTransactionsCaption {
    font-size: 12px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.00042765619582496583px;
    text-align: left;
    height: 44px;
    vertical-align: middle;
    padding-left: 16px !important;
    border-bottom: 1px solid var(--grey-200);
}

.modalTransactionsTable {
    height: calc(100% - 44px);

    &Compact {
        height: calc(100% - 232px);
    }
}

.widgetTabs {
    height: 100%;
}

.widgetTabPanel {
    height: calc(100% - 40px);
    overflow-y: auto;
}

.fitParentHeight {
    height: 100%;
}

.overviewTab {
    // zendesk tablist overrides it and doesnt have a property to pass for it
    margin-bottom: 0 !important;
}

.modalDatePickerCalendar {
    right: 10px;
}

.insightsTab {
    padding: 1.3rem 1rem 1rem 1rem;

    .insightsRow {

        .insightsHeading {
            font-size: .6rem;
            font-weight: 600;
            text-transform: uppercase;
            color: var(--grey-500);
        }

        .insightsBody {
            font-size: .75rem;

            ul {
                padding-left: 1rem;
                padding-bottom: .75rem;
                margin-bottom: 0;

                li {
                    margin-bottom: .25rem;
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: .75rem;

            .insightsBody {
                border-bottom: 1px solid var(--grey-100);
            }
        }
    }
}