
.tree-category-title {
    padding-left: 8px;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 36px;
}

.node-depth-0 {
    color: var(--darkliver);
    padding-bottom: 32px;
    margin-left: 40px;
    font-size: 14px;
}

.node-depth-1 {
    color: var(--darkliver);
    margin-left: 21px;
    padding-bottom: 12px;
    padding-top: 12px;
    font-size: 14px;
}

.node-depth-2 {
    color: var(--darkliver);
    margin-left: 21px;
    padding-bottom: 12px;
    padding-top: 12px;
    font-size: 14px;
}

.node-depth-3 {
    color: var(--darkliver);
    margin-left: 21px;
    font-size: 14px;
}

.arrow-forward-icon,
.arrow-down-icon {
    font-size: 18px;
    margin-right: 8px;
}

.sidebar-account-link {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-right: 36px;
}

.sidebar-account-default {
    max-width: 185px;
    min-width: 185px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    border-radius: 7px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;

    margin-top: -4px;
    margin-bottom: -8px;
}

.sidebar-account-link:hover {
    background-color: var(--white-smoke);
    margin-right: 24px;
}

.active-link{
    .account-selected {
        background-color: var(--light-azure);
        margin-right: 24px;
        border: 1px solid var(--glacier);
    }
}




.account-unselected {
    background-color: var(--white);
}

.category-expanded {
    margin-bottom: 16px;
}
