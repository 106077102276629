@import '../../../colors';

.staticLoader{
    display:block;
    min-height: 8px;

    &Loading{
        background-color: map-get($theme-colors, "grey-200");
    }

    .loadbar{
        display:block;
        min-height: 8px;

        &Loading{
            background-color: map-get($theme-colors, "primary-600");
        }
    }
}