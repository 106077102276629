.widget {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--grey-200);
    border-radius: 4px;
}

.widgetHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-200);
    padding: 12px;

    > div {
        flex-grow: 0;
        max-width: 100%;
    }
}

.widgetModeActions {
    display: flex;
    column-gap: 6px;
}

.modeToggleButton {
    border: transparent !important;
}

.widgetLabelContainer {
    padding-left: 4px;
}

.widgetLabel {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--grey-600);
    text-transform: uppercase;
    padding-bottom: 0.5rem;
}

.widgetSublabel {
    display: flex;
    align-items: baseline;
    font-size: 0.6rem;
    font-weight: 500;
    color: var(--grey-500);
    text-transform: uppercase;
    flex-grow: 0;

    .widgetSublabelMetricDivider {
        margin-right: .25rem;
    }
}

.graphPane {
    display: flex;
    padding: 10px;
    width: 100%;
    height: 460px;
    min-height: 460px;

    div {
        flex-grow: 1;
    }
}

.tablePane {
    display: flex;
    height: 460px;

    div {
        flex-grow: 1;
    }

    .inner {
        width: 486px;
        height: 460px;
        overflow: auto;
    }
}

.widgetTableHeader {
    background-color: white !important;
    text-align: left !important;
    padding: 0.4rem 0.4rem 0 0.4rem !important;
    font-weight: 600 !important;
    font-size: 0.75rem !important;
    height: 1.9rem !important;
}

.widgetTableHeaderAlignRight {
    @extend .widgetTableHeader;
    text-align: right !important;
}