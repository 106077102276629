@import "../../../../fonts.scss";

.container {
    height: 100%;
    width: 100%;
    padding-bottom: 16px;
}

.pageTitle {
    @extend .text-xxl-default-semi-bold;
    color: var(--grey-600);
    height: 68px;
}

.pageContent {
    height: calc(100% - 68px);
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--grey-200);

    &Header {
        height: 64px;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-left: 16px;
        padding-right: 16px;
    }

    &Body {
        height: calc(100% - 64px);
        width: 100%;
    }
}

.headerLabel {
    @extend .text-md-default-semi-bold;
    height: 100%;
    display: flex;
    align-items: center;
}

.headerPropertyFilter {
    height: 100%;
    max-width: 246px;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.propertiesSelector {
    max-width: 246px;
    width: 246px;
}

.headerSettingsButton {
    margin-left: 16px;
    height: 100%;
    display: flex;
    align-items: center;
}

// BEGIN TODO:: remove me and wrap reports properly.
// the report must be drop in and know nothing about
// surrounding css context

.container {
    margin-top: 100px;
    height: calc(100% - 100px);

    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
}
// END TODO:

