$ease-in-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

.flapGameWrapper {
    position: relative;
    width: 700px;
    height: 500px;
    display: flex;
    justify-content: left;
    margin-bottom: 10rem;
    border-radius: 4px;
    background-color: var(--royal-50);
    overflow: hidden;
    margin: 1rem 0;

    .playerScore {
        transition: all .5s $ease-in-out-expo;
        position: absolute;
        opacity: 0;
        top: 16px;
        left: 2px;
        z-index: 2;
        color: #ffffff;
        text-shadow: 1px 1px 6px rgba(black, 1);
    }

    .uiOverlayStartScreen {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        transition: all .5s $ease-in-out-expo;
        z-index: 2;
        visibility: visible;
        justify-items: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .title {
            width: 500px;
            margin-bottom: 2rem;
            z-index: 1;
        }

        .instructions {
            background-color: rgba(#ffffff, .5);
            border-radius: 300px;
            padding: 1rem 2rem;
            margin-bottom: 2rem;
            z-index: 1;
        }

        .difficultySelections {
            display: flex;
            gap: 1rem;
            margin-bottom: 2rem;
            z-index: 1;
        }

        button {
            z-index: 1;
            margin-bottom: 5rem;
        }

        .bounce {
            animation: bounce 2s ease infinite;
          }

          @keyframes bounce {
              30% { transform:translateY(0%); }
              45% { transform:translateY(-15%); }
              100% { transform:translateY(0%); }
          }

        .startBackground {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;
        }

        .highScore {
            z-index: 1;
        }
    }

    .gameCanvas {
     z-index: 1;
     position: absolute;
     border-radius: 4px;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
    }

    &.gameStarted {

        .playerScore {
            opacity: 1;
            left: 16px;
        }

        .uiOverlayStartScreen {
            opacity: 0;
            top: 54%;
            visibility: hidden;
        }
    }
}
