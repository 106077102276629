.spreadsheetContainer {
    border: 1px solid var(--grey-200);
    align-self: center;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;

    &.spreadsheetContainerNested {
        border: none;
    }
}

:global .vz-content {
    padding-right: 0 !important;

    &:first-child {
        height: 100%;
    }
}

.accountFormulaBar {
    display: flex;
    column-gap: 14px;
    padding: 8px 8px 7px 14px;

    background-color: var(--grey-50);

    border-top: 1px solid var(--grey-200);
    border-right: 1px solid var(--grey-200);
    border-left: 1px solid var(--grey-200);
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.formulaDateRange {
    display: flex;
    align-items: center;
    font-size: 0.7em;
    font-weight: 600;
    white-space: nowrap;
    color: var(--grey-800);
}

.lockBanner {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    border: 1px solid var(--grey-300);
    background-color: var(--grey-50);
    border-radius: 4px;
    padding: 1.25rem 1rem;
    font-size: .875rem;
    font-weight: 500;

    &.lockBannerFixed {
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    .lockBannerIcon {
        margin-right: .5rem;
        color: var(--grey-500);
    }
}

.lockMenu {
    max-width: 300px;

    .lockMenuHeaderText {
        font-size: .75rem;
        color: var(--grey-500);
        padding-left: 2.2rem;
        font-weight: 500;
    }
}