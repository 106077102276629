@import './colors';

:root {
    --overrideTootipWidth: 200px;
}

/* TODO: Remove from generic styles to Button component styles once button component is used instead of plain divs. @rackuka */
.btn {
    padding: 8px 0 8px 0;
    border: 1px solid color("--primary");
    // margin: 0 10px 0 0;
    min-width: 140px;
    color: var(--white);
    height: 40px;
    font-size: 0.9em;
    border-radius: 12px;
}

input::-webkit-input-placeholder {
    color: var(--medium-grey);
}

.d-contents {
    display: contents;
}

.main-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
}

._reports {
    width: 100%;
    margin-top: 90px;
    min-height: calc(100% - 90px);

    iframe {
        width: 100%;
        min-height: 100%;
    }
}

._modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
}

.transparent,
.see-through {
    background: rgba(0, 0, 0, 0.4);
}

.blur {
    backdrop-filter: blur(20px);
}

.darkener {
    backdrop-filter: brightness(40%);
}

.shimmer {
    max-width: 1600px;
    animation: shim 1.3s ease-in infinite;
    overflow: hidden;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        display: block;
        align-self: center;
    }
}

._loader {
    z-index: 9;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
}

.loader-content {
    margin-top: 100px;
    background: white;
    border-radius: 100px;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-starter {
    backdrop-filter: blur(50px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .as-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .app-img {
        width: 200px;
        transition: all 0.3s;

        img {
            width: 100%;
        }
    }

    .as-text {
        width: 200px;
        font-size: 14px;
        // animation: popin 0.3s ease-in;
        animation-delay: 0.2s;
        user-select: none;
        border: 1px solid #0c7f87;
        padding: 10px;
        margin-top: 10px;
        border-radius: 2px;
        display: flex;
        justify-content: center;

        span {
            animation: shim 1.3s ease-in infinite;
        }
    }
}

.vz-wrapper {
    display: flex;
    height: calc(100% - 70px);
    margin-top: 70px;
    overflow: hidden;
    width: 100%;
    background: #F7F5F5;
}

.vz-container {
    height: 100%;
    position: relative;
    width: 100%;
    overflow-x: auto;
}

.vz-content {
    width: 100%;
    left: 0;
    background-color: #FFFFFF;
    position: absolute;
    bottom: 0;
    overflow-y: auto;
    //padding-right: 20px;
    top: 0;
}

.vz-alert {
    background: white;
    width: 100%;
    max-width: 375px;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 50, 0.4) 0 0 10px 0;
}

.strip-btn {
    background: transparent;
    border: 0 !important;
    min-width: auto !important;
}

.reno-tb {
    box-shadow: rgba(210, 210, 209, 0.4) 0px -1px 10px 0px;
    background: white;
    border-radius: 13px;
    font-size: 13px;
    // margin-right: 20px;
    border-collapse: collapse;
    border-spacing: 0;
    width: calc(100% - 40px);

    tr {
        border-bottom: 1px solid rgb(232, 231, 231);
    }

    tr:last-child {
        border-bottom: 0;
    }

    thead {
        height: 60px;

        tr {
            border-bottom: 1px solid rgb(207, 205, 205) !important;
        }
    }

    .t-highlight {
        background: var(--bg-grey);
    }

    th {
        border: 0 !important;
    }

    .mx-title {
        min-width: 200px;
    }

    td {
        padding: 0 10px;
        position: relative;
    }

    .rn-value {
        text-align: right;
        height: 45px;
        max-width: 80px;
        overflow: hidden;
    }

    .rn-bordered {
        border: 1px solid #e5eaea;
    }

    .rn-hgl {
        background: var(--light-azure);
        border: 1px solid #e5eaea;
        color: #0a8585;
    }

    .rn-child {
        border: 0;
        border-bottom: 1px solid var(--bg-grey);
    }

    .td-title {
        width: 200px;
    }


    .td-cell-active {
        border: 2px solid #0a8585;
    }
}

.editable-input {
    border: 2px solid #0a8585;
    width: 100%;
    height: 100%;
    color: black;
    caret-color: black;
}

.child-tree {
    padding-left: 20px;
}

.indent-node {
    margin-left: 24px;
}

.cell-value {
    padding: 10px;
    // width: 180px;
}

.row-title-btn-content {
    position: absolute;
    right: 0;
    background: #ffffffe0;
}

.tooltip-btn {
    position: relative;
    padding: 4px 10px;
    cursor: pointer;
    display: none;
}

.tooltip-inner {
    background: rgb(235, 234, 234) !important;
    color: rgb(75, 75, 75) !important;
    box-shadow: rgba(50, 50, 50, 0.4) 0 -1px 3px 0;
    font-size: 11.4px !important;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: rgb(235, 234, 234) !important;
}

.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: white !important;
}

.ov-t {
    margin-left: 15px !important;

    .tooltip-inner {
        background: white !important;
        box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px 0 !important;
    }
}

.with-tooltip:hover {
    .tooltip-btn {
        display: block;
    }
}

.properties-select {
    .properties-select__control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.react-select-theme__control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.bold {
    font-weight: 600;
}

.override {
    z-index: 1000;
    position: absolute;
    top: 46px;

    .clip-spread {
        height: 48px;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
    }

    button {
        outline: none !important;
    }

    .ov-td {
        height: 50px;
        min-width: 70px;
        max-width: 70px;
        border: 1px solid var(--bg-grey);
        cursor: default;
        position: relative;
    }

    .overriden {
        background: #F3E9EF;
        // box-shadow: inset -1px 1px 0px #B57AA0, inset 1px -1px 0px #B57AA0;
    }

    .isloated {
        // margin-top: calc(75px + 16px + 8px + 48px + 78px);
        // margin-top: calc(75px + 16px + 8px + 3px + 78px);
        // margin-top: calc(123px + 16px + 8px + 3px + 78px);
        display: flex;
        width: fit-content;
        padding-left: 0;
    }


    .ov-td.overriden {
        border: 2px solid #B57AA0;
    }

    .ov-cell {
        font-size: 12px;
        position: relative;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .ov-val {
        padding: 5px;
    }

    .ov-title {
        width: 161px;
        font-weight: bold;
        max-width: none;

        .ov-cell {
            display: unset;
        }

        .ov-val {
            padding-left: 8px;
        }

        * {
            text-align: left;
        }
    }



}

.ov-master-btn,
.ov-tt-btn {
    padding: 5px 10px;
    height: auto !important;
    min-width: auto !important;
    font-size: 12px;
    border-radius: 4px;
}

.override-tooltip {
    .ov-tooltip {
        // position: absolute;
        min-width: var(--overrideTootipWidth);
        right: calc(((var(--overrideTootipWidth) + 16px)) * (-1));
        background: white;
        box-shadow: rgba(0, 0, 0, 0.4) 0 0 10px 0;
        border-radius: 4px;
        z-index: 2;
        // top: 25px;
    }

    .ov-tooltip::after {
        content: '';
        position: absolute;
        top: calc(100% - 1px);
        right: 20px;
        background: #fff;
        width: 20px;
        height: 8px;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
    }

    .ov-tt-tg-btn {
        position: absolute;
        transform: rotate(225deg);
        right: -3.3px;
        bottom: -8px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid var(--dark-pink);
        cursor: pointer;
        z-index: 1;
    }

    .ov-tt-head {
        padding: 4px;
        line-height: 12px;
    }

    .ov-pd-title {
        text-transform: uppercase;
        font-size: 10.2px;
        padding-bottom: 3px;
    }

    .ov-tt-content {
        margin-bottom: 10px;
        margin-top: -16px;
        padding-right: 40px;
        padding-left: 10px;
    }

    .ov-tt-actions {
        overflow: hidden;
        border-radius: 0 0 4px 4px;
        padding-right: 6px;
        padding-bottom: 5px;
        padding-top: 5px;
        background-color: rgba(248, 249, 249, 0.5);
    }

    .ov-tt-btn {
        border-radius: 0;
    }

    .ov-pad {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .ov-pd-val {
        padding: 2px 10px;
        height: 25px;
        border-radius: 12.5px;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        min-width: 70px;
    }
}

// .ov-tooltip-anchor {
//     border-width: 12px;
//     content: "";
//     border-style: solid;
//     border-color: #900 #fff #090 #009;
// }

.ov-iso-btn {
    background: white;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -48px;
    left: 55px;
    cursor: pointer;
    z-index: 1001;
    animation: bounce-top 1.5s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
}

.ov-states {
    // width: calc(100% - 161px);
    width: 982px;
    height: 2px;
    position: absolute;
    top: 91px;
    left: 161px;
    display: flex;
}

.override {
    .ov-states {
        top: 44px;
    }
}

.ov-state-box {
    width: 70px;
    height: 2px;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .ov-tt-tg-btn {
        right: -2.3px;
        bottom: -7px;
    }
    .ov-state-cell{
        background: #f3e9ef;
        height: 46px;
        width: calc(100% - 2px);
        position: absolute;
        bottom: 0;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: var(--dark-pink);
    }


    .ov-tooltip {
        top: -21px;
        right: calc(((var(--overrideTootipWidth) + 39px)) * (-1));
    }
    .ov-tooltip::after{
        left: -4%;
    }

}

@keyframes popin {
    0% {
        transform: translate(0, 12px);
        opacity: 0.2;
    }

    100% {
        transform: translate(0);
        opacity: 1;
    }
}

@keyframes shim {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce-top {
    0% {
        transform: translateY(-95px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    24% {
        opacity: 1;
    }

    40% {
        transform: translateY(-44px);
        animation-timing-function: ease-in;
    }

    65% {
        transform: translateY(-18px);
        animation-timing-function: ease-in;
    }

    82% {
        transform: translateY(-9px);
        animation-timing-function: ease-in;
    }

    93% {
        transform: translateY(-6px);
        animation-timing-function: ease-in;
    }

    25%,
    55%,
    75%,
    87% {
        transform: translateY(2px);
        animation-timing-function: ease-out;
    }

    100% {
        transform: translateY(2px);
        animation-timing-function: ease-out;
        opacity: 1;
    }
}


@media screen and (max-width: 800px) {
    .shimmer {
        width: calc(100% - 20px);
    }
}

@media only screen and (min-width: 1500px) {
    .main-container {
        width: 1500px;
    }
}

div[gcuielement="gcEditingInput"]::selection {
    background-color: #B57AA0;
}

.marker-do-not-remove {
    color:rgb(75, 75, 75)
}
