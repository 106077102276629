.reportsContainer{
    position: relative;
    z-index: 1;
    display: flex;
    margin-top: 72px;
    min-height: calc(100vh - 80px);
    padding: 0 20px 0 20px;

    & * {
        min-height: unset;
    }
}

.reportLabelContainer{
    margin-bottom: 6px;
}

.reportBody{
    width: 100%;
}