@use "sass:map";
@import "./App.css";
@import "./styles.scss";

@import "./fonts.scss";
@import "../node_modules/bootstrap/scss/functions";
@import "./colors.scss";

$theme-colors: (
    "primary": #00B1A7,
    "primary-600": #107F88,
    "secondary": #FF5C00,
    "white": #FFFFFF,
    "grey20": #333333,
    "bg-grey": #F7F7F7,
    "yellow": #FBB755,
    "mint": #ACE2D4,
    "danger": red,

    "nickel": #737373,
    "off-white": #f2f2f2,
    "darkliver": #4f4f4f,
    "grey": #7b7b7b,
    "grey3": #828282,
    "lite-grey": #e0e0e0,
    "medium-grey": #bdbdbd,
    "litesilver": #d8d8d8,
    "primary-medium-gray": #D3D3D3,
    "secondary-medium-gray": #F9F9F9,
    "linen": #fff0e4,
    "pattens-blue": #dbebea,
    "philippine-grey": #929292,
    "whisper": #ededed,
    "white-smoke": #f0f0f0,
    "pink-swan": #B7B7B7,
    "nobel": #9c9c9c,
    "blueberry": #4e95ff,
    "alice-blue": #e9f2f8,
    "lavender": #e0edf8,
    "ghost-white": #f5fbff,
    "blue-ribbon": #006ae5,
    "mabel": #c6e9e7,
    "silver": #c5c5c5,
    "gainsboro": #e0e0e0,
    "soft-orange": #f9c8ad,
    "powder-blue": #ade2df,
)!default;

:root {
    // colors
    --primary: #00B1A7;
    --primary-600: #107F88;
    --secondary: #FF5C00;
    --white: #FFFFFF;
    --grey20: #333333;
    --bg-grey: #FAFBFB;
    --yellow: #FBB755;
    --mint: #ACE2D4;
    --danger: #FF0000;

    --nickel: #737373;
    --off-white: #f2f2f2;
    --darkliver: #4f4f4f;
    --grey: #7b7b7b;
    --grey3: #828282;
    --lite-grey: #e0e0e0;
    --medium-grey: #bdbdbd;
    --litesilver: #d8d8d8;
    --primary-medium-grey: #D3D3D3;
    --border-gray: #e5e5e5;
    --background-gray: #f5f5f5;
    --linen: #fff0e4;
    --pattens-blue: #dbebea;
    --philippine-grey: #929292;
    --whisper: #ededed;
    --white-smoke: #f0f0f0;
    --pink-swan: #B7B7B7;
    --nobel: #9c9c9c;
    --blueberry: #4e95ff;
    --alice-blue: #e9f2f8;
    --lavender: #e0edf8;
    --ghost-white: #f5fbff;
    --blue-ribbon: #006ae5;
    --mabel: #c6e9e7;
    --silver: #c5c5c5;
    --gainsboro: #e0e0e0;
    --soft-orange: #f9c8ad;
    --powder-blue: #ade2df;

    // fonts
    --times-new-roman-font-stack: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
};
$text-primary: var(--primary);
$text-secondary: var(--secondary);
$input-placeholder-color: var(--medium-grey);
$text-tab-inactive: var(--philippine-grey);
$text-tab-active: var(--grey20);

$dropdown-link-active-color: var(--darkliver);
$dropdown-link-active-bg: var(--white);

$h2-font-size: 1.7rem;
$h3-font-size: 1.4rem;
$h4-font-size: 1.2rem;
$h5-font-size: .9rem;
$h6-font-size: .7rem;

$modal-sm: 200px;
$modal-md: 400px;

$enable-rounded: true;

$form-check-input-checked-bg-color: var(--primary);
$form-check-input-focus-border: var(--primary);
$form-check-input-focus-box-shadow: var(--primary);

$body-bg: #F7F7F7;
$component-bg: white;

// $spacer: 5rem;

@import "../node_modules/bootstrap/scss/variables";

@import "../node_modules/bootstrap/scss/mixins";

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";


@import "./buttons.scss";

@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";

$navbar-light-brand-color:                map.get($theme-colors, "secondary");
$navbar-light-brand-hover-color:          map.get($theme-colors, "secondary");
$navbar-dark-brand-color:                 map.get($theme-colors, "secondary");
$navbar-dark-brand-hover-color:           map.get($theme-colors, "secondary");
$navbar-brand-font-size: 32px;
$navbar-padding-x: 40px;

@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";

.hide-for-NNA-demos{
    display: none !important;
}

.nav-link {
    white-space: nowrap;
}

.navbar {
    background-color: $component-bg;
    border-bottom-color: var(--border-gray) !important;
    border-bottom: 1px;
    border-bottom-style: solid;
}

.navbar-brand {
    font-weight: bold;
}
.nb-link-icon {
    font-size: 24px !important;
    margin-bottom: 5px;
    margin-right: 6px;
}

.nb-link {
    font-size: 16px !important;
    margin-right: $nav-link-padding-x;
}

.nb-link-last {
    font-size: 16px !important;
    margin-right: 0;
    padding-right: 0 !important;
}

.nb-link-icon-last {
    font-size: 24px !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}



@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";

@import "../node_modules/bootstrap/scss/utilities";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";

.gc-scroll-container .btn {
    min-width: auto;
    background-color: #A8ACB4;
    border: none;
}

@import "styles/highcharts/ViziblyHighcharts";
