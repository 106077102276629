.report-note-container {
    width: 300px;
    .report-add-note-button {
        color: #0D666D !important;
        background-color: transparent !important;
        &:hover {
            color: #0A5257 !important;
            background-color: #E7F0F0 !important;
        }
    }
    .report-view-note-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
        .report-view-note-fill-icon {
            color: #525A6A;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            width: 25px;
        }
        .report-view-note-text-container {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            flex: 1;
            height: 32px;
            .report-view-note-text {
                max-height: 32px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #333842;
                font-family: Inter;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .report-view-note-expand-icon-container {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            width: 35px;
            visibility: hidden;
            .report-view-note-expand-icon {
                border-radius: 50%;
                width: 35px;
                height: 35px;
                color: #0D666D;
                padding: 7px 11px;
                &:hover {
                    background-color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }
        &:hover {
            .report-view-note-expand-icon-container {
                visibility: visible !important;
            }
        }
    }
}

.report-note-text-area {
    width: 100%;
    height: 100%;
    border: 1px solid white;
    background-color: var(--off-white);
    border-radius: 8px;
}
