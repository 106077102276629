.bodyContainer {
    .summaryContainer {
        border-top: 1px solid #E9EBED;
        border-left: 1px solid #E9EBED;
        background-color: #F7F8F9;
        font-size: 12px;
        .rowHeight {
            min-height: 32px;
        }
        .rowBorderHeight {
            border-bottom: 1px solid #E9EBED;
            min-height: 32px;
        }
        .colBorder {
            border-right: 1px solid #E9EBED;
        }
        .headingRow {
            font-size: 10px;
        }
        .unitNumberContainer {
            background-color: #68737D;
            color: white;
            margin-top: 8px;
        }
        .tenantNameContainer {
            display: block;
            padding: 6px 4px 0 0;
        }
        .termLeaseTag {
            background-color: #2C63B4;
            color: white;
        }
        .renewalTag {
            background-color: #3D858A;
            color: white;
        }
        .mtmTag {
            background-color: #F6A694;
            color: white;
        }
        .strTag {
            background-color: #F6D78D;
            color: white;
        }
        .renovationTag {
            background-color: #E9B9D3;
            color: white;
        }
        .termLeaseTheme {
            background-color: #E4F0FF;
        }
        .renewalTheme {
            background-color: #E5F0F0;
        }
        .mtmTheme {
            background-color: #FEF4F2;
        }
        .strTheme {
            background-color: #FDF6E3;
        }
        .renovationTheme {
            background-color: #FBEEf4;
        }
    }
    .fieldsContainer {
        margin-top: 20px;
        .rowResetPadding {
            .col:first-child {
                padding-left: 0 !important;
            }
            .col:last-child {
                padding-right: 0 !important;
            }
        }
        .field {
            margin-bottom: 20px !important;
            .label {
                font-family: Inter;
                color: #2F3941;
                font-size: 12px;
                font-weight: 600;
            }
            .optionItem {
                font-size: 14px;
                .disabledReason {
                    font-size: 10px;
                }
            }
        }
        .linkIcon {
            transform: rotateZ(45deg);
        }
        .inputSuffixDays {
            width: 44px;
        }
        .inputSuffixMonths {
            width: 61px;
            color: #667085;
        }
        .inputPrefixDollor {
            width: 15px;
        }
        .inputSuffixPercentage {
            width: 15px;
        }
    }
    .middle {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .middleCenter {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.footer {
    border-top: 1px solid #E9EBED;
    justify-content: space-between !important;
    .right {
        display: flex;
    }
}
