@import "../../colors";

$analyst-drawer-padding: 1rem;

.header {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    height: 75px;
    .headerText {
        padding: $analyst-drawer-padding $analyst-drawer-padding 0;
    }
    .closeButton {
        position: absolute;
        top: $analyst-drawer-padding;
        right: $analyst-drawer-padding;
        min-width: unset;
        width: 1rem;
        height: 1rem;
    }
}

.headerActionsBar {
    background-color: #F8FAFB;
    padding: 1rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.sheetContainer {
    display: flex;
    position: relative;
    .addPackageButtonContainer {
        position: absolute;
        .addPackageButton{
            display: block;
            width: 243px;
            height: 92px;
            border: none;
            background-color: #FFFFFF;
            text-indent: -9999em;
            background-image: url('../../assets/images/analyst/renovations/renovation_drawer_dummy_bg_add_package.png');
        }
    }
}

.footerActionsBar {
    background-color: #F8FBFC;
    padding: 0.25rem 1rem 0.25rem 1rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    .uploadingMessageContainer {
        padding: 0.5rem 1rem;
    }
    .footerActionsBarCancelButton {
        margin-right: 1rem;
        color: #0A8585;
    }
    .footerActionsBarUpdateButton {
        background-color: #0A8585;
    }
}
