.treeCategoryTitle {
    padding-left: 8px;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 36px;
}

.leftNavFont{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #525A6A;
}

.nodeDepth0 {
    color: var(--darkliver);
    padding-bottom: 32px;
    margin-left: 20px;
    font-size: 14px;
}

.nodeDepth1 {
    color: var(--darkliver);
    margin-left: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    font-size: 14px;
}

.nodeDepth2 {
    color: var(--darkliver);
    margin-left: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    font-size: 14px;
}

.nodeDepth3 {
    color: var(--darkliver);
    margin-left: 20px;
    font-size: 14px;
}

.sidebarCategoryTable{
    padding-top: 15px;
}

.sidebarAccountLink {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &:hover{
        color: #0D666D;
        cursor: pointer;
    }
}

.sidebarAccountDefault {
    max-width: 185px;
    min-width: 185px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-left: 12px;

    border-radius: 7px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;

    margin-top: -4px;
    margin-bottom: -8px;
}

.arrowForwardIcon,
.arrowDownIcon {
    float:right;
    font-size: 16px;
    margin-right: 16px;
}

.activeLink{
    &:hover{
        color: #0D666D;
        cursor: pointer;
    }

    .accountSelected {
        background: #DBE8E9;
        font-weight: 600;
        color: #0D666D;
    }

}
