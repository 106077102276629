.form {
    max-width: 600px;
}

.field {
    margin-bottom: 1em;

    label {
        color: var(--grey-800);
        font-weight: 500;
    }

    .propertyLabel {
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
        justify-content: space-between;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
        margin-left: 0.25em;
    }
}
