.cellLocator{
    background: rebeccapurple;
}

.headerRow0EmptyCell{
    height: 22px !important;
    border-bottom: 1px solid white !important;
}

.headerGroupLabel{
    width: 100%;
    background-color: var(--primary-100);

    border-bottom: 1px solid var(--primary-100) !important;

    color: var(--grey-500);
    vertical-align: bottom;

    font-weight: 500 !important;
    text-align: center !important;

    & :global .relative {
        padding-bottom: 2px !important;
    }
}

.headerRow1Label{
    height: 50px !important;
    color: var(--grey-400);
    padding: 0 0 12px 14px !important;
    vertical-align: bottom;
    font-weight: 600 !important;
}

.headerRow1ValueColumn{
    height: 50px !important;
    color: var(--grey-500);
    background-color: var(--primary-50);
    padding: 0 12px 12px 14px !important;
    text-align: right !important;
    vertical-align: bottom;
    font-weight: 500 !important;
    border-right: 1px solid var(--primary-50) !important;
}

.headerRow1ValueColumnLast{
    height: 50px !important;
    color: var(--grey-500);
    background-color: var(--primary-50);
    padding: 0 12px 12px 14px !important;
    text-align: right !important;
    vertical-align: bottom;
    font-weight: 500 !important;
}